import React from 'react';

const SaveIcon = () => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512 512" xmlSpace="preserve">
            <g>
                <g>
                    <path d="M508.885,88.448L423.552,3.115C421.547,1.131,418.837,0,416,0H32C14.357,0,0,14.357,0,32v448c0,17.643,14.357,32,32,32
                        h448c17.643,0,32-14.357,32-32V96C512,93.163,510.869,90.453,508.885,88.448z M490.667,480c0,5.888-4.8,10.667-10.667,10.667H32
                        c-5.867,0-10.667-4.779-10.667-10.667V32c0-5.888,4.8-10.667,10.667-10.667h379.584l79.083,79.083V480z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M373.333,0H138.667C132.779,0,128,4.779,128,10.667v128c0,17.643,14.357,32,32,32h192c17.643,0,32-14.357,32-32v-128
                        C384,4.779,379.221,0,373.333,0z M362.667,138.667c0,5.888-4.8,10.667-10.667,10.667H160c-5.867,0-10.667-4.779-10.667-10.667
                        V21.333h213.333V138.667z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M394.667,192H117.333C87.915,192,64,215.936,64,245.333v256C64,507.221,68.779,512,74.667,512h362.667
                        c5.888,0,10.667-4.779,10.667-10.667v-256C448,215.936,424.085,192,394.667,192z M426.667,490.667H85.333V245.333
                        c0-17.643,14.357-32,32-32h277.333c17.643,0,32,14.357,32,32V490.667z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M330.667,42.667H288c-5.888,0-10.667,4.779-10.667,10.667v64c0,5.888,4.779,10.667,10.667,10.667h42.667
                        c5.888,0,10.667-4.779,10.667-10.667v-64C341.333,47.445,336.555,42.667,330.667,42.667z M320,106.667h-21.333V64H320V106.667z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M373.333,277.333H138.667c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h234.667
                        c5.888,0,10.667-4.779,10.667-10.667S379.221,277.333,373.333,277.333z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M373.333,341.333H138.667c-5.888,0-10.667,4.779-10.667,10.667c0,5.888,4.779,10.667,10.667,10.667h234.667
                        c5.888,0,10.667-4.779,10.667-10.667C384,346.112,379.221,341.333,373.333,341.333z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M373.333,405.333H138.667c-5.888,0-10.667,4.779-10.667,10.667c0,5.888,4.779,10.667,10.667,10.667h234.667
                        c5.888,0,10.667-4.779,10.667-10.667C384,410.112,379.221,405.333,373.333,405.333z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            </svg>
    )
}

export default SaveIcon;
export const userData = (state = [], action) => { 
    switch (action.type) {
        case 'USER_DATA':
            return action.value;      
        default:
            return state
    }
}

export const gallery = (state = [], action) => { 
    switch (action.type) {
        case 'GALLERY':
            return action.value;      
        default:
            return state
    }
}

export const lastPageGallery = (state = 1, action) => { 
    switch (action.type) {
        case 'LAST_PAGE_GALLERY':
            return action.value;      
        default:
            return state
    }
}

export const media = (state = [], action) => { 
    switch (action.type) {
        case 'MEDIA':
            return action.value;      
        default:
            return state
    }
}

export const chats = (state = [], action) => { 
    switch (action.type) {
        case 'CHATS':
            return action.value;      
        default:
            return state
    }
}

export const openedChats = (state = [], action) => { 
    switch (action.type) {
        case 'OPENED_CHATS':
            return action.value;
        
        case 'OPENED_CHATS_REMOVE':
            if ( action.value !== -1 ) {
                const   cloneArray = [...state];
                cloneArray.splice(action.value, 1);
                return cloneArray;   
            } else {
                return state;
            }
           
        default:
            return state
    }
}

export const menus = (state = [], action) => { 
    switch (action.type) {
        case 'MENUS':
            return action.value;      
        default:
            return state
    }
}

export const pages = (state = [], action) => { 
    switch (action.type) {
        case 'PAGES':
            return action.value;      
        default:
            return state
    }
}

export const postTypes = (state = [], action) => { 
    switch (action.type) {
        case 'POST_TYPES':
            return action.value;      
        default:
            return state
    }
}

export const languages = (state = [], action) => { 
    switch (action.type) {
        case 'LANGUAGES':
            return action.value;      
        default:
            return state
    }
}

export const userPermissions = (state = [], action) => { 
    switch (action.type) {
        case 'USER_PERMISSIONS':
            return action.value;      
        default:
            return state
    }
}

export const accountTypes = (state = [], action) => { 
    switch (action.type) {
        case 'ACCOUNT_TYPES':
            return action.value;      
        default:
            return state
    }
}

export const tags = (state = [], action) => { 
    switch (action.type) {
        case 'TAGS':
            return action.value;      
        default:
            return state
    }
}

export const categories = (state = [], action) => { 
    switch (action.type) {
        case 'CATEGORIES':
            return action.value;      
        default:
            return state
    }
}

export const translates = (state = [], action) => { 
    switch (action.type) {
        case 'TRANSLATES':
            return action.value;      
        default:
            return state
    }
}

export const socket = (state = null, action) => { 
    switch (action.type) {
        case 'SOCKET':
            return action.value;      
        default:
            return state
    }
}

export const users = (state = [], action) => { 
    switch (action.type) {
        case 'USERS':
            return action.value;      
        default:
            return state
    }
}

export const countData = (state = [], action) => { 
    switch (action.type) {
        case 'COUNT_DATA':
            return action.value;      
        default:
            return state
    }
}

const langState = {
    shortcode: "PL",
    name: "Polski",
    flag_icon: "https://api.e-etykieta.com/flags/pl.png",
    id: 1,
}

export const lang = (state = langState, action) => { 
    switch (action.type) {
        case 'LANG':
            return action.value;      
        default:
            return state
    }
}

export const notificationAlertText = (state = "", action) => { 
    switch (action.type) {
        case 'NOTIFICATION_ALERT_TEXT':
            return action.value;      
        default:
            return state
    }
}

export const contrast = (state = "", action) => { 
    switch (action.type) {
        case 'CONTRAST_CHANGE':
            return action.value;      
        default:
            return state
    }
}

export const iconsApp = (state = "", action) => { 
    switch (action.type) {
        case 'ICONS_APP':
            return action.value;      
        default:
            return state
    }
}

export const companies = (state = "", action) => { 
    switch (action.type) {
        case 'COMPANIES':
            return action.value;      
        default:
            return state
    }
}

export const selectedCompany = (state = false, action) => { 
    switch (action.type) {
        case 'SELECTED_COMPANY':
            return action.value;      
        default:
            return state
    }
}



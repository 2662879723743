import React, { useState, useEffect } from 'react'
import Button from './../button/Button';
import { useSelector, useDispatch } from 'react-redux'
import removeIcon from './../../images/svg/remove-tag.svg';
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import Input from './../../components/input/Input';

const Tags = ( props ) => {
    let [ value, setValue ] = useState({
        value: '',
        id: 0,
    });
    let [ tagsPage, setTagsPage ] = useState([]);
    let tags = useSelector(state => state.tags);
    let pageID = typeof window !== "undefined" && (window.location.pathname).split("/");pageID = pageID[pageID.length -1]
    let [ resultChoose, setResultChoose ] = useState(false);
    let [ disableBtn, setDisableBtn ] = useState(false);
    const dispatch = useDispatch();
    let selectedCompany = useSelector(state => state.selectedCompany);

    useEffect(() => {
        if(props.page && props.page.tags) {
            getTagsPage(props.page.tags);
        }
    }, [props.page])

    const getTagsPage = (data) => {
        let newTags = [];
        data && data.map(tag => {
            if(tag.data) {
                newTags = [...newTags, tag.data];
            }
        })
        setTagsPage(newTags);
    }

    const removeTag = (index) => {
        let removeTag = tagsPage[index];
        axios({
            method: 'post',
            url: `${ApiUrl()}/removeTagFromPage`,
            data: {
                tagID: removeTag.id,
                pageID: pageID,
                userID: localStorage.getItem('userId'),
                token: localStorage.getItem('token'),
                selectedCompany: selectedCompany,
            },
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status) {
                let newTagsPageArr = tagsPage;
                // newTagsPageArr.splice(index, 1);
                newTagsPageArr = newTagsPageArr.filter((item, i) => index == i ? false : item)

                setTagsPage(newTagsPageArr);
            }
            //tags
        })
    }

    const checkResultChoose = (val) => {
        if(val.length > 2) {
            let result = [];
            tags && tags.map(tag => {
                if((tag.name).toLowerCase().substring(0, val.length) == val.toLowerCase()) {
                    result = [...result, tag];
                }
            })
            setResultChoose(result);
        }else{
            setResultChoose([]);
        } 
    }

    const addTag = () => {
        if(!disableBtn) {
            setDisableBtn(true);
            let errors = false;
            if(!value || value.value.length == 0) {
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Należy wprowadzić nazwę tagu</p>` })
                errors = true;
            }
    
            if(errors) {
                setDisableBtn(false);
            }
    
            if(!errors) {
                axios({
                    method: 'post',
                    url: `${ApiUrl()}/newTagPage`,
                    data: {
                        tagID: value.id,
                        pageID: pageID,
                        newTagName: value.value,
                        langID: props.languagePageID,
                        userID: localStorage.getItem('userId'),
                        token: localStorage.getItem('token'),
                        selectedCompany: selectedCompany,
                    },
                }).then(response => {
                    let res = JSON.parse(response.request.response);
                    if(res.status == true) {
                        if(value.id == 0) {
                            let newAllTags = tags;
                            newAllTags = [...newAllTags, res.tag];
                            dispatch({ type: 'TAGS', value: newAllTags })

                            let dataTagsPage = tagsPage;
                            dataTagsPage = [...dataTagsPage, res.tag];
                            setTagsPage(dataTagsPage)
                        }else{
                            let dataTagsPage = tagsPage;
                            dataTagsPage = [...dataTagsPage, res.tag];
                            setTagsPage(dataTagsPage)
                        }

                        setValue({
                            value: '',
                            id: 0,
                        })
                        setDisableBtn(false);
                    }else{
                        dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Twój tag prawdopodobnie już został przypisany do tej etykiety</p>` })
                        setDisableBtn(false);
                    }
    
                })
            }
        }
    }

    const saveChoose = (val) => {
        setValue(val); 
        setResultChoose(false);
    }

    const handleKeyDown = (e, functionName, functionValue = false, functionValueSecond = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue, functionValueSecond)
        }
    }

    useEffect(() => {

    }, [value])

    return (
        <div className="tags settings-box">
            <div className="header">
                <span>Tagi</span>
            </div>
            <div className="content">
                {!props.disabled &&
                <>
                    <div className="new-tag">
                        {/* <input class="input-tags" type="text" value={value.value} onChange={(e) => { setValue({value: e.target.value, id: value.id}); checkResultChoose(e.target.value) } } /> */}
                        <Input  required={ false }
                            setFunction={ val => { setValue({value: val, id: 0}); checkResultChoose(val) } }
                            value={ value.value }
                            regExp={ /.*/ }
                            disabled={props.disabled}
                            placeholder="nazwa"
                        />
                        <div className="result">
                            {resultChoose && resultChoose.map((res, index) => (
                                <div key={index} 
                                    className="result-choose" 
                                    role="button"
                                    tabIndex="0"
                                    onKeyPress={ (e) => handleKeyDown(e, saveChoose, {value: res.name, id: res.id})}
                                    onClick={() => saveChoose({value: res.name, id: res.id}) }>
                                    <p>{res.name}</p>
                                </div>
                            ))}
                        </div>
                        <div className="submit">
                            <Button title="Dodaj" setFunction={() => addTag()} />
                        </div>
                    </div>
                    <small>Dodawaj tagi pojedyńczo</small>
                </>
                }
                <div className="tags" data-ct={tagsPage.length}>
                    {tagsPage && tagsPage.map((tag, index) => (
                        <div className="tag" 
                            onClick={() => !props.disabled && removeTag(index)}
                            aria-label={`Usuń ${tag?.name}`}
                            role="button"
                            tabIndex="0"
                            onKeyPress={ (e) => !props.disabled && handleKeyDown(e, removeTag, index)}>
                            <p>{tag?.name}</p>
                            {!props.disabled &&
                                <div className="remove">
                                    <img src={removeIcon} alt="remove" />
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Tags;

import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import ApiUrl from './../../services/ApiUrl';
import Input from './../../components/input/Input';
import TrashIcon from './../../svg-components/TrashIcon';
import JoinIcon from './../../svg-components/JoinIcon';
import UnBlockIcon from './../../svg-components/UnBlockIcon';
import ReadChatIcon from './../../svg-components/ReadChatIcon';
import Pagination from './../pagination/Pagination';
import Loader from './../loader/Loader';
import HeaderSort from './HeaderSort';
import Popup from './../popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import WindowChat from './../../components/live-chat/WindowChat';
import UntrashIcon from '../../svg-components/UntrashIcon';
import Select from './../../components/select/Select';

const Table = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ data, setData ] = useState(false);
    let [ loader, setLoader ] = useState(true);
    let [ currentPage, setCurrentPage ] = useState(1);
    let [ pageSize, setPageSize ] = useState(10);
    let [ maxPages, setMaxPages ] = useState(1);
    let [ haveFirstData, setHaveFirstData ] = useState(false);
    let [ header, setHeader ] = useState(false);
    let [ filtered, setFiltered ] = useState(false);
    let [ removeDataIndex, setRemoveDataIndex ] = useState(false);
    let [ openChat, setOpenChat ] = useState(false);
    let [ languagesData, setLanguagesData ] = useState(false);
    let languages = useSelector(state => state.languages);
    let history = useHistory();
    let selectedCompany = useSelector(state => state.selectedCompany);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!load) {
            ;(async function() {
                await setCurrentPage(1);
                await getFilterData(props.dbTable, props.langID, props.postType, 1);
                await createHeader(props.dbTable);
                await setLoad(true);
            })()
        }
    },[load]);

    useEffect(() => {
        if(load) {
            setLoader(true);
            setLoad(false);
        }
    }, [props.postType, props.langID])

    useEffect(() => {
        if(!languagesData && languages && languages.length > 0) {
            createLanguagesData();
        }
    }, [props.langID, languages])

    useEffect(() => {
        setLoad(false);
    }, [selectedCompany])

    const createLanguagesData = () => {
        let newData = [];
        languages.map((lang, index) => {
            newData = [...newData, {
                value: lang.id,
                text: lang.shortcode,
                ariaLabel: `Język ${(lang.name).toLowerCase()}`
            }]
        })
        setLanguagesData(newData);
    }

    const getFilterData = (dbTable = false, langID = false, postType = false, pageNumber = false, size = false, filteredBy = "", filteredValue = "", sortBy = "", sortAscOrDesc = "") => {
        pageNumber = pageNumber ? pageNumber : currentPage;
        size = size ? size : pageSize
        let trash = false;
        let apiEndpoint;
        if(dbTable == "pages") {
            apiEndpoint = "pagesListPagination";
        }else if(dbTable == "users") {
            apiEndpoint = "usersListPagination"
        }else if(dbTable == "translates") {
            apiEndpoint = "translatesListPagination"
        }else if(dbTable == "tags") {
            apiEndpoint = "tagsListPagination"
        }else if(dbTable == "companies") {
            apiEndpoint = "companiesListPagination"
        }

        if(postType == "trash") {
            trash = true;
            postType = "labels";
        }
        axios({
            method: 'get',
            url: `${ApiUrl()}/${apiEndpoint}?langID=${langID}&postType=${postType}&page=${pageNumber}&pageSize=${size}&filteredBy=${filteredBy}&filteredValue=${filteredValue}&sortedBy=${sortBy}&sortedAscOrDesc=${sortAscOrDesc}&userId=${localStorage.getItem('userId')}&trash=${trash}&selectedCompany=${selectedCompany}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            if(response.status == 200) {
                let res = JSON.parse(response.request.response);
                setMaxPages(res.last_page);
                setData(res.data);
                setLoader(false);
                setHaveFirstData(true);
            }else{
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować później</p>` }) 
            }
        })
    }

    const createHeader = (dbTable = false) => {
        let newHeader;
        if(dbTable == "pages") {
            newHeader = [
                {
                    text: "Nazwa",
                    accessor: "title",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Kategoria",
                    accessor: "category",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Tag",
                    accessor: "tags",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Status",
                    accessor: "status",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Język",
                    accessor: "language",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Utworzono",
                    accessor: "created_at",
                }, 
                {
                    text: "ID",
                    accessor: "id",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Akcje",
                    accessor: ["remove", (props.postType != "trash" ? "join" : ""), (props.postType == "trash" ? "untrash" : "")]
                }
            ]
            if(props.postType == "trash") {
                newHeader = [
                    {
                        text: "ID",
                        accessor: "id",
                        filter: true,
                        filterValue: "",
                    },
                    {
                        text: "Nazwa",
                        accessor: "title",
                        filter: true,
                        filterValue: "",
                    },
                    {
                        text: "Kategoria",
                        accessor: "category",
                        filter: true,
                        filterValue: "",
                    },
                    {
                        text: "Tag",
                        accessor: "tags",
                        filter: true,
                        filterValue: "",
                    },
                    {
                        text: "Język",
                        accessor: "language",
                        filter: true,
                        filterValue: "",
                    },
                    {
                        text: "Utworzono",
                        accessor: "created_at",
                    }, 
                    {
                        text: "Akcje",
                        accessor: ["remove", (props.postType != "trash" ? "join" : ""), (props.postType == "trash" ? "untrash" : "")]
                    }
                ]
            }
        }else if(dbTable == "users") {
            newHeader = [
                {
                    text: "ID",
                    accessor: "id",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Login",
                    accessor: "login",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Email",
                    accessor: "email",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Imię",
                    accessor: "name",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Nazwisko",
                    accessor: "surname",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: 'Uprawnienia',
                    accessor: 'account_type',
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Utworzono",
                    accessor: "created_at",
                }, 
                {
                    text: "Akcje",
                    accessor: ["remove", "join"]
                }
            ]
        }else if(dbTable == "translates") {
            newHeader = [
                {
                    text: "ID",
                    accessor: "id",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Klucz",
                    accessor: "meta_key",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "PL",
                    accessor: "translate_pl",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "EN",
                    accessor: "translate_en",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Akcje",
                    accessor: ["remove", "join"]
                }
            ]
        }else if(dbTable == "tags") {
            newHeader = [
                {
                    text: "ID",
                    accessor: "id",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Nazwa",
                    accessor: "name",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Akcje",
                    accessor: ["remove"]
                }
            ]
        }else if(dbTable == "companies") {
            newHeader = [
                {
                    text: "ID",
                    accessor: "id",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Nazwa",
                    accessor: "name",
                    filter: true,
                    filterValue: "",
                },
                {
                    text: "Akcje",
                    accessor: ["join"]
                }
            ]
        }
        setHeader(newHeader)
    }

    const updateValueFilter = (val, index) => {
        let copyHeader = header;
        copyHeader[index].filterValue = val;
        setHeader(copyHeader);
    }

    const refreshDataTable = async(pageNumber, size = false, filteredBy = "", filteredValue = "", sortBy = "", sortAscOrDesc = "", filteredReset = false) => {
        await setLoader(true);
        await setCurrentPage(pageNumber);
        if(size) {
            await setPageSize(size);   
        }
        if(!filteredReset) {
            if(filteredBy && filteredBy.length > 0 && filteredValue && filteredValue.length > 0) {
                setFiltered({
                    filteredBy: filteredBy, 
                    filteredValue: filteredValue
                })
            }else{
                if(filtered?.filteredBy && filtered?.filteredValue) {
                    filteredBy = filtered?.filteredBy;
                    filteredValue = filtered?.filteredValue;
                }
            }
        }
        await getFilterData(props.dbTable, props.langID, props.postType, pageNumber, size, filteredBy, filteredValue, sortBy, sortAscOrDesc);
    }

    const join = (ID) => {
        let page = "page";
        if(props.dbTable == "users") {
            page = "user";
        }
        history.push(`${props.postType}/${page}/${ID}`)
    }

    const trash = () => {
        setOpenChat(false);
        props.removeItem(data[removeDataIndex].id);
        let newData = data;
        newData.splice(removeDataIndex, 1);
        setRemoveDataIndex(false);
        setData(newData);
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const getAriaActions = (type, row) => {
        if(type == "trash") {
            if(props.dbTable == "pages") {
                return `Usuń ${row['title']}`;
            }else if(props.dbTable == "users") {
                return `Usuń użytkownika ${row['login']}`;
            }else if(props.dbTable == "translates") {
                return `Usuń tłumaczenie - ${row['translates'][0]?.value}`
            }else if(props.dbTable == "questions") {
                return `Usuń ${row['title']}`;
            }else if(props.dbTable == "clinics") {
                return `Usuń ${row['title']}`;
            }
        }else if(type == "join") {
            if(props.dbTable == "pages") {
                return `Wejdź do ${row['title']}`;
            }else if(props.dbTable == "users") {
                return `Sprawdź użytkownika ${row['login']}`;
            }else if(props.dbTable == "translates") {
                return `Sprawdź tłumaczenie - ${row['translates'][0]?.value}`
            }else if(props.dbTable == "questions") {
                return `Wejdź do ${row['title']}`;
            }else if(props.dbTable == "clinics") {
                return `Wejdź do ${row['title']}`;
            }
        }else if(type == "unban") {
            if(props.dbTable == "bans") {
                return `Odblokuj użytkownika ${row['id']}`
            }
        }else if(type == "show") {
            if(props.dbTable == "bans") {
                return `Otwórz rozmowę przez którą użytkownik ${row['id']} został zablokowany`
            }
        }
    }

    const searchFilter = (accessor, value) => {
        let copyHeader = header;
        copyHeader && copyHeader.map((h, i) => {
            if(h.accessor != accessor) {
                h.filterValue = "";
            }
        })
        setHeader(copyHeader);
        let resetFilter = false;
        if(!value || value.length == 0) {
            setFiltered(false);
            resetFilter = true;
        }

        refreshDataTable(1, false, accessor, value, "", "", resetFilter)
    }

    const untrashPage = (index) => {
        let url = `${ApiUrl()}/untrashPage`
        axios({
            method: 'post',
            // url: `${ApiUrl()}/removePage`,
            url: url,
            header: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: {
                userID: localStorage.getItem('userId'),
                token: localStorage.getItem('token'),
                removePageID: data[index].id,
            }
        }).then(response => {
            let results = JSON.parse(response.request.response);
            if(results.status == false) {
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
            }
            let newData = data;
            newData.splice(removeDataIndex, 1);
            setData(newData);
            props.decrementCountData();
        });
    }

    const clearAll = () => {
        setLoad(false);
    }

    const changeLanguage = (id) => {
        let value;
        languages.map(lang => {
            if(lang.id == id) {
                // value = {
                //     shortcode: lang.shortcode, id: lang.id
                // }
                value = lang;
            }
        });

        if(value) {
            dispatch({ type: 'LANG', value: value })
        }

        window.localStorage.setItem('LANG', JSON.stringify(value));
    }

    return (
        <div className="container-table">
            {(props.postType != "trash" && props.postType != "users") &&
                <>
                    {(removeDataIndex || removeDataIndex === 0) &&
                        <Popup 
                            close={() => setRemoveDataIndex(false)} 
                            action={() => trash()} 
                            btnText={"Potwierdzam"} 
                            editor={`<h2>Czy aby na pewno chcesz przenieść do kosza ${data[removeDataIndex]?.title} wraz z wersjami językowymi?</h2><p>Akcji nie bedzie można cofnąć.</p>`} 
                        />
                    }
                </>
            }
            {props.postType == "trash" &&
                <>
                    {(removeDataIndex || removeDataIndex === 0) &&
                        <Popup 
                            close={() => setRemoveDataIndex(false)} 
                            action={() => trash()} 
                            btnText={"Potwierdzam"} 
                            editor={`<h2>Czy aby na pewno chcesz usunąć ${data[removeDataIndex]?.title} wraz z wersjami językowymi?</h2><p>Akcji nie bedzie można cofnąć.</p>`} 
                        />
                    }
                </>
            }
            {props.postType == "users" &&
                <>
                    {(removeDataIndex || removeDataIndex === 0) &&
                        <Popup 
                            close={() => setRemoveDataIndex(false)} 
                            action={() => trash()} 
                            btnText={"Potwierdzam"} 
                            editor={`<h2>Czy aby na pewno chcesz usunąć ${data[removeDataIndex]?.name} ${data[removeDataIndex]?.surname} ?</h2><p>Akcji nie bedzie można cofnąć.</p>`} 
                        />
                    }
                </>
            }
            {loader &&
                <div className="loader-wrapper">
                    <div className="loader">
                        <Loader />
                    </div>
                </div>
            }
            <div className="wrapper-table">
                <div className="header">
                    <div className="row">
                        {header && header.map((headerItem, index) => (
                            <div className={`col ${headerItem.accessor == "id" ? 'small' : ''}  ${headerItem.accessor == "title" ? 'big' : ''}`} key={index}>
                                <div className="text">
                                    <HeaderSort setFunction={(ascOrDesc) => !Array.isArray(headerItem.accessor) && refreshDataTable(1, false, filtered.filteredBy , filtered.filteredValue, headerItem.accessor, ascOrDesc) } text={headerItem.text} />
                                </div>
                                {headerItem.filter &&
                                    <div className="filter">
                                        {headerItem.accessor != "language" &&
                                            <Input  
                                                type={ headerItem.accessor == "id" ? "number" : "text" }
                                                ariaLabel={ `Wyszukaj po ${headerItem.text}` }
                                                required={ false }
                                                setFunction={ val => updateValueFilter(val, index) }
                                                onClickEnter={ () => searchFilter(headerItem.accessor , headerItem.filterValue) }
                                                value={ headerItem.filterValue }
                                                regExp={ /.*/ }
                                                minChar={ 0 } 
                                            />
                                        }
                                        {headerItem.accessor == "language" &&
                                            <Select name="language"
                                                ariaLabel="Wybierz język dla którego mamy pobrać zawartość panelu administracyjnego"
                                                data={ languagesData } 
                                                value={ props.langID }
                                                handle={ (id) => { changeLanguage(id) } } />
                                        }
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
                <div className="clear">
                    <p onClick={() => clearAll()}>Wyczyść wyniki wyszukiwania</p>
                </div>
                <div className="results">
                    {data && data.map((row, i) => (
                        <div className="row">
                            {header && header.map((headerItem, index) => (
                                <>
                                    {Array.isArray(headerItem.accessor) &&
                                        <div className={`col ${headerItem.accessor == "id" ? 'small' : ''} ${headerItem.accessor == "title" ? 'big' : ''}`}>
                                            <div className="actions-box">
                                                {headerItem.accessor.includes("remove") &&
                                                    <div className="action" onKeyDown={(e) => handleKeyDown(e, setRemoveDataIndex, i)} onClick={() => setRemoveDataIndex(i)} role="button" tabIndex="0" aria-label={getAriaActions('trash', row)}>
                                                        <TrashIcon />
                                                    </div>
                                                }
                                                {headerItem.accessor.includes("join") &&
                                                    <div className="action" onKeyDown={(e) => handleKeyDown(e, join, row.id)} onClick={() => join(row.id)} role="button" tabIndex="0" aria-label={getAriaActions('join', row)}>
                                                        <JoinIcon />
                                                    </div>
                                                }
                                                {headerItem.accessor.includes("unban") &&
                                                    <div className="action" onKeyDown={(e) => handleKeyDown(e, setRemoveDataIndex, i)} onClick={() => setRemoveDataIndex(i)} role="button" tabIndex="0" aria-label={getAriaActions('unban', row)}>
                                                        <UnBlockIcon />
                                                    </div>
                                                }
                                                {headerItem.accessor.includes("show") &&
                                                    <div className="action" onKeyDown={(e) => handleKeyDown(e, setOpenChat, i)} onClick={() => setOpenChat(i)} role="button" tabIndex="0" aria-label={getAriaActions('show', row)}>
                                                        <ReadChatIcon />
                                                    </div>
                                                }
                                                {headerItem.accessor.includes("untrash") &&
                                                    <div className="action" onKeyDown={(e) => handleKeyDown(e, setOpenChat, i)} onClick={() => untrashPage(i)} role="button" tabIndex="0" aria-label={getAriaActions('untrash', row)}>
                                                        <UntrashIcon />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {!Array.isArray(headerItem.accessor) &&
                                        <div className={`col ${headerItem.accessor == "id" ? 'small' : ''} ${headerItem.accessor == "title" ? 'big' : ''}`}>
                                            <div className="text"> 
                                            {(headerItem.accessor == "title" || headerItem.accessor == "id") && props.postType == "labels" &&
                                                <p class="link" onClick={() => join(row.id)}>{row[headerItem.accessor]}</p>
                                            }
                                            {(headerItem.accessor == "title" || headerItem.accessor == "id") && props.postType != "labels" &&
                                                <p>{row[headerItem.accessor]}</p>
                                            }
                                            {headerItem.accessor == "user_id" &&
                                                <p>{row[headerItem.accessor].login}</p>
                                            }
                                            {headerItem.accessor == "language" &&
                                                <div className="langs">
                                                {row && row.relation_langs && row.relation_langs.map((lang, i) => (
                                                    <p class="link" onClick={() => join(lang.id)}>{lang.language.shortcode}{i == (row.relation_langs.length -1) ? "" : ","}</p>
                                                ))}
                                                </div>
                                            }
                                            {/* {headerItem.accessor == "category" &&
                                                <p>{row[headerItem.accessor][0]?.data.name}</p>
                                            } */}
                                            {headerItem.accessor == "account_type" &&
                                                <p>{row[headerItem.accessor]?.name}</p>
                                            }
                                            {headerItem.accessor == "translate_pl" &&
                                                <p>{row.translates[0].value}</p>
                                            }
                                            {headerItem.accessor == "translate_en" &&
                                                <p>{row.translates[1].value}</p>
                                            }
                                            {/* {headerItem.accessor == "type" &&
                                                <p>{getTextType(row[headerItem.accessor])}</p>
                                            } */}
                                            {headerItem.accessor !== "user_id" && 
                                            // headerItem.accessor !== "category" && 
                                            headerItem.accessor !== "account_type" &&
                                            headerItem.accessor !== "translate_pl" &&
                                            headerItem.accessor !== "translate_en" &&
                                            headerItem.accessor !== "title" &&
                                            headerItem.accessor !== "id" &&
                                            headerItem.accessor !== "language" &&
                                            // headerItem.accessor !== "type" &&
                                                <p>{row[headerItem.accessor]}</p>
                                            }
                                            </div>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="pagination">
                {haveFirstData &&
                    <Pagination currentPage={currentPage} maxPages={maxPages} pageSize={pageSize} setPageSize={(size) => refreshDataTable(1, size) } changeCurrentPage={(newCurrent) => refreshDataTable(newCurrent)} />
                }
            </div>
            {openChat !== false &&
                <div className="opened-chats">
                    <WindowChat data={ data[openChat].chat } 
                        closeChat={ () => setOpenChat(false) } 
                    />
                </div>
            }
        </div>
    )
}   

export default Table;
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import clientLogo from './../../../images/client-logo.png';
import clientLogoBlack from './../../../images/client-logo-black.png';
// import Logo from './../../../svg-components/Logo';
import Logo from './../../../images/svg/qr-logo.svg';
import { useSelector } from 'react-redux'
import Arrow from './../../../svg-components/Arrow';

const Navbar = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ toggleMenu, setToggleMenu ] = useState(false);
    let userPermissions = useSelector(state => state.userPermissions);
    let [ active, setActive ] = useState(false)
    let [ activeChildren, setActiveChildren ] = useState(false)
    let contrast = useSelector(state => state.contrast);

    useEffect(() => {
        if(!load) {
            if (window.matchMedia("(max-width: 1300px)").matches) {
                setToggleMenu(true)
              } else {
                setToggleMenu(false)
            }
            setLoad(true)
        }
    }, [load])

    useEffect(() => {
        if(typeof document !== "undefined") {
            if(toggleMenu) {
                document.getElementsByTagName('main')[0]?.classList.add("minimalize-menu")
            }else{
                document.getElementsByTagName('main')[0]?.classList.remove("minimalize-menu")
            }
        }
    }, [toggleMenu])

    useEffect(() => {
        let arrayLocation = props.location.pathname.split('/');
        let arrayLength = arrayLocation.length;

        if(arrayLength == 4) {
            setActive(arrayLocation[arrayLength - 2]);
            setActiveChildren(arrayLocation[arrayLength - 1]);
        }else{
            setActive(arrayLocation[arrayLength - 1]);
            setActiveChildren(false);
        }
    }, [props.location])

    let handleKeyDownSlide = (e) => {
        if (e.key === 'Enter') {
            setToggleMenu(!toggleMenu)
        }
    }

    
    return (
        <>
            <div className={toggleMenu ? "ghost-menu minimalize" : "ghost-menu"} />
            <nav className={toggleMenu ? "menu minimalize" : "menu"} id="menu">
                <div className="menu-wrapper">
                    <div className="client">
                        <img src={ Logo } alt="norddigital" />
                        {/* <Logo /> */}
                    </div>
                    <div className="slide-wrapper-btn" tabIndex="0" aria-label={toggleMenu ? "Rozwiń menu" : "Zwiń menu"} role="button" onClick={ () => setToggleMenu(!toggleMenu) } onKeyDown={(e) => handleKeyDownSlide(e)}>
                        <div className="slide-menu">
                            <Arrow />
                        </div>
                    </div>
                    <div className="menu-box">
                        <ul>
                            {/* <li className={active == "dashboard" ? "active" : ""}>
                                <Link to="/dashboard" tabIndex="0" role="button" aria-label="Strona główna">
                                    <i className="material-icons" aria-hidden={true}>dashboard</i>
                                    <span>Strona główna</span>
                                </Link>
                            </li> */}
                            {userPermissions && userPermissions.includes("page-pages") &&
                            <li id="pages-menu" className={active == "pages" ? "active" : ""}>
                                <Link to="/dashboard/labels" tabIndex="0" role="button" aria-label="Lista etykiet" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>labels</i>
                                    <span>Etykiety</span>
                                </Link>
                                <ul>
                                    <li className={active == "pages" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/labels/new" tabIndex="0" role="button" aria-label="Dodaj nową etykiete">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowa etykieta</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-trash") &&
                            <li id="settings-menu" className={active == "trash" ? "active" : ""}>
                                <Link to="/dashboard/trash" tabIndex="0" role="button" aria-label="usunięte etykiety">
                                    <i className="material-icons" aria-hidden={true}>delete</i>
                                    <span>Usunięte etykiety</span>
                                </Link>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-library") &&
                            <li id="library-menu" className={active == "media-library" ? "active" : ""}>
                                <Link to="/dashboard/media-library" tabIndex="0" role="button" aria-label="Biblioteka zdjęć">
                                    <i className="material-icons" aria-hidden={true}>slideshow</i>
                                    <span>Biblioteka zdjęć</span>
                                </Link>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-categories") &&
                            <li id="settings-menu" className={active == "categories" ? "active" : ""}>
                                <Link to="/dashboard/categories" tabIndex="0" role="button" aria-label="Kategorie">
                                    <i className="material-icons" aria-hidden={true}>category</i>
                                    <span>Kategorie</span>
                                </Link>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-categories") &&
                            <li id="settings-menu" className={active == "tags" ? "active" : ""}>
                                <Link to="/dashboard/tags" tabIndex="0" role="button" aria-label="Tagi">
                                    <i className="material-icons" aria-hidden={true}>tags</i>
                                    <span>Tagi</span>
                                </Link>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-dashboard") && !userPermissions.includes("page-users") &&
                            <li id="profil" className={active == "profil" ? "active" : ""}>
                                <Link to="/dashboard/profil" tabIndex="0" role="button" aria-label="Twój profil">
                                    <i className="material-icons" aria-hidden={true}>person</i>
                                    <span>Twój profil</span>
                                </Link>
                            </li>
                            } 
                            {userPermissions && userPermissions.includes("page-users") &&
                            <li id="users-menu" className={active == "users" ? "active" : ""}>
                                <Link to="/dashboard/users" tabIndex="0" role="button" aria-label="Lista użytkowników" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>people</i>
                                    <span>Użytkownicy</span>
                                </Link>
                                <ul>
                                    <li className={active == "users" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/users/new" tabIndex="0" role="button" aria-label="Dodaj nowego użytkownika">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-translates") &&
                            <li id="pages-translates" className={active == "translates" ? "active" : ""}>
                                <Link to="/dashboard/translates" tabIndex="0" role="button" aria-label="Lista tłumaczeń" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>g_translate</i>
                                    <span>Tłumaczenia</span>
                                </Link>
                                <ul>
                                    <li className={active == "translates" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/translates/new" tabIndex="0" role="button" aria-label="Dodaj nowe tłumaczenie">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowe tłumaczenie</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-companies") &&
                            <li id="settings-menu" className={active == "companies" ? "active" : ""}>
                                <Link to="/dashboard/companies" tabIndex="0" role="button" aria-label="firmy">
                                    <i className="material-icons" aria-hidden={true}>business</i>
                                    <span>Firmy</span>
                                </Link>
                            </li>
                            }
                        </ul>               
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;

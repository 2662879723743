import React, { useState, useEffect } from 'react'
import Input from './../../../../components/input/Input';
import axios from 'axios';
import ApiUrl from './../../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'
import PageTitle from './../../../../components/page-title/PageTitle';
import Button from './../../../../components/button/Button';
import readTextNvda from './../../../../services/readTextNvda';
import Editor from './../../../../components/editor/Editor';

const Company = (props) => {
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false)
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ name, setName ] = useState("");
    let [ information, setInformation ] = useState("");
    let pageID = typeof window !== "undefined" && (window.location.pathname).split("/");pageID = pageID[pageID.length -1]
    let [ forceValidate, setForceValidate ] = useState(false);

    useEffect(() => {
        if (!load) {
            ;(async function() {
                await getCompany();
                await setLoad(true);
            })()
        }
    },[load]);

    const getCompany = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getCompany?pageID=${pageID}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setName(res.name);
            setInformation(res.information);
        })
    }

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(!errors) {
            save();
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.company-container .error');

        if( elements.length > 0 ) {
            readTextNvda("translate");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = () => {
        if(!blockedBtn) {
            setBlockedBtn(true);
            axios({
                method: 'post',
                url: `${ApiUrl()}/saveCompany`,
                data: {
                    pageID: pageID,
                    token: localStorage.getItem('token'),
                    name: name,
                    info: information,
                    userID: localStorage.getItem('userId'),
                    edit: true,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status = "success") {
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Zaaktualizowano firme</p>` }) 
                }else{
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                }
            })
        }
    }

    return (
        <div className="container company-container">
            <div className="wrapper">
                <PageTitle title="Edytuj firme" />
                <div className="form">
                    <div className="input-row">
                        <Input  name="name"
                            required={ true }
                            label="Nazwa"
                            type="text" 
                            value={ name }
                            disabled={ false } 
                            setFunction={ (val) => setName(val) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    <div className="input-row">
                        <Editor required={true} forceValidate={ forceValidate } setFunction={ val => setInformation(val) } value={ information } label="Informacje o firmie do stopki etykiety" />
                    </div>
                    <div className="input-save">
                        <Button title="Zapisz" setFunction={() => validate()} class="btn-secondary" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Company;

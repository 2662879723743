import React, { useState, useEffect } from 'react';
import MagnifyIcon from './../../svg-components/MagnifyIcon';
import EyeIcon from './../../svg-components/EyeIcon';

export default (props) => {
	let [ value, setValue ] = useState('');
	let [ error, setError ] = useState(false);
	let [ load, setLoad ] = useState(false);
	let [ errorText, setErrorText ] = useState(false);
	let [ modeStatus, setModeStatus ] = useState(false);

	useEffect(
		() => {
			if (!load) {
				setValue(props.value);
				setLoad(true);
			}
		},
		[ load ]
	);

	useEffect(
		() => {
			if (load) {
				validate();
			}
		},
		[ props.forceValidate ]
	);

	useEffect(
		() => {
			if (load) {
				setValue(props.value);
			}
		},
		[ props.value ]
	);
	const validate = () => {
		let val = props.value;
		// check if empty
		if (props.required) {
			if (!val || val.length == 0) {
				setErrorText('To pole jest wymagane.');
				setError(true);
				return false;
			} else {
				setError(false);
			}

			//check length
			if (val.length < props.minChar) {
				setError(true);
				setErrorText('Za mało znaków.' + ' Min: ' + props.minChar);
				return false;
			} else {
				setError(false);
			}

			//check format
			if (props.regExp) {
				let regExp = new RegExp(props.regExp);
				if (regExp.test(val)) {
					setError(false);
				} else {
					setError(true);
					setErrorText('Zły format.');
				}
			}
		}

		return true;
	};

	const onKeyDown = (e) => {
		if (e.key === 'Enter' && props.onClickEnter) {
			e.preventDefault();
			props.onClickEnter();
		}
	};

	const filterText = (e) => {
		let newText = e.target.value;
		newText = newText.replace("&", "");
		props.setFunction(newText);
		setValue(newText);
	}

	return (
		<div
			className={
				'input-wrapper-component ' +
				(error ? 'error ' : '') +
				(props.class ? props.class : 'btn-primary') +
				(props.type ? ' input-' + props.type : '') +
				(props.disabled ? ' disabled' : '') +
				(props.mode ? ' ' + props.mode : '')
			}
			data-alert={errorText}
		>
			{props.label && <label for={props.name}>{props.label}</label>}
			<div className="input-box">
				<input
					id={props.name}
					type={props.mode == "password" ? (modeStatus ? "text" : "password") : props.type ? props.type : 'text'}
					value={value}
					min="1"
					disabled={props.disabled}
					max={props.max ? props.max : false}
					placeholder={props.placeholder}
					aria-label={props.ariaLabel ? props.ariaLabel : props.label}
					onChange={(e) => {
						filterText(e)
					}}
					onKeyDown={(e) => onKeyDown(e)}
				/>
				{props.type == 'search' && (
					<div className="magnify" onClick={() => props.onClickEnter && props.onClickEnter()}>
						<MagnifyIcon />
					</div>
				)}
				{props.mode == 'password' &&
					<div className="password-icon" onClick={() => setModeStatus(!modeStatus)}>
						<EyeIcon />
					</div>
				}
			</div>
			{error && <span className="error-text">{errorText}</span>}
		</div>
	);
};

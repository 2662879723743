import checkSession from './checkSession';
import actualDate from './actualDate';
import logout from './logout';

const redirectSession = (props) => {
    let now = actualDate();
    let sessionDate = localStorage.getItem('sessionDate');
    let checkStatusSession = checkSession(sessionDate, now, 300);
    if(sessionDate) {
        if(!checkStatusSession) {
            logout(props);
        }else{
            localStorage.setItem('sessionDate', now);
        }
    }
}

export default redirectSession;
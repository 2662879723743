import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TagsIcon from '../../../svg-components/TagsIcon';
import InformationCard from '../../../components/information-card/InformationCard';
import Button from '../../../components/button/Button';
import Table from '../../../components/table/Table';
import axios from 'axios'
import ApiUrl from '../../../services/ApiUrl';
import PageTitle from '../../../components/page-title/PageTitle';
import Popup from './../../../components/popup/Popup';

const Tags = (props) => { 
    let [ load, setLoad ] = useState(false);
    let countData = useSelector(state => state.countData);
    let lang = useSelector(state => state.lang);
    let tags = useSelector(state => state.tags);
    let [ countPostType, setCountPostType ] = useState(0);
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[postType.length -1]
    let [ removeData, setRemoveData ] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(countData) {
            setCountPostType(countData[postType])
        }
    }, [countData, postType])

    useEffect(() => {
        if(!load) {
            setLoad(true);
        }
    }, [load])

    const goToPage = () => {
        props.history.push(`/dashboard/${postType}/new`)
    }

    const removeTag = (ID, name, index) => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/removeTag`,
            header: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: {
                userID: localStorage.getItem('userId'),
                token: localStorage.getItem('token'),
                tagID: ID,
            }
        }).then(response => {
            let results = JSON.parse(response.request.response);
            setRemoveData(false)
            if(results.status == "success") {
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Tag #${name} został usunięty</p>` })
                let newTagsArr = tags;
                newTagsArr.splice(index, 1);
                dispatch({ type: 'TAGS', value: newTagsArr })
            }else{
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
            }
            //SPRAWDZIĆ DZIAŁANIE
            decrementCountData();
        });
    }

    const decrementCountData = () => {
        let count = --countData[postType];
        let newCountData = countData;
        newCountData[postType] = count;
        setCountPostType(count)
        dispatch({ type: 'COUNT_DATA', value: newCountData })
    }

    return (
        <div className="container pages-container">
            {removeData &&
                <Popup 
                    close={() => setRemoveData(false)} 
                    action={() => removeTag(removeData.id, removeData.name, removeData.index)} 
                    btnText={"Potwierdzam"} 
                    editor={`<h2>Czy aby na pewno chcesz usunąć ${removeData.name} ?</h2><p>Akcji nie bedzie można cofnąć.</p>`} 
                />
            }
            <PageTitle title={"Tagi"} />
            <div className="wrapper">
                <div className="actions">
                    <div className="count">
                        <InformationCard text={`<p>Wszystkich tagów: <strong>${countPostType ? countPostType : 0}</strong></p>`} icon={<TagsIcon />} />
                    </div>
                    {/* <div className="btn-box">
                        <Button class="btn-secondary" title="Dodaj nowy tag" icon="add" setFunction={() => goToPage()} />
                    </div> */}
                </div>
                <div className="table-result">
                    {/* <Table dbTable="tags" langID={lang.id} postType={postType} removeItem={(ID) => removeItem(ID)} /> */}
                    <div className="tags-list">
                        {tags && tags.map((tag, index) => (
                            <div className="tag" key={tag.id}>
                                <p>{tag.name}</p>
                                <div class="svg-icon" aria-hidden="true" onClick={() => setRemoveData({id: tag.id, name: tag.name, index: index})}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
                                        <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>       
    );
}

export default Tags;

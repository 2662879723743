import React, { useState, useEffect, useRef } from 'react'
import Input from '../../../../components/input/Input'
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'
import Get from '../../../../services/Get';
import Select from '../../../../components/select/Select';
import Categories from './../../../../components/boxes/Categories';
import StatusPage from './../../../../components/boxes/StatusPage';
import PageTitle from './../../../../components/page-title/PageTitle';
import Button from './../../../../components/button/Button';
import readTextNvda from './../../../../services/readTextNvda';
import SaveIcon from './../../../../svg-components/SaveIcon';
import Editor from './../../../../components/editor/Editor';
import MediaLoad from './../../../../components/media-load/MediaLoad';
import Avatar from 'react-avatar-edit';

const NewPage = (props) => {
    let [ load, setLoad ] = useState(false)
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ title, setTitle ] = useState('');
    let [ status, setStatus ] = useState("w edycji");
    let language = useSelector(state => state.lang);
    let [ langID, setLangID ] = useState();
    let categories = useSelector(state => state.categories);
    const dispatch = useDispatch();
    let [ resultPage, setResultPage ] = useState();
    let languages = useSelector(state => state.languages);
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[2]
    let countData = useSelector(state => state.countData);
    let icons = useSelector(state => state.iconsApp);
    let selectedCompany = useSelector(state => state.selectedCompany);
    let [ categoriesArray, setCategoriesArray ] = useState([]);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ typeWine, setTypeWine ] = useState("");
    let [ productInfo, setProductInfo ] = useState("");
    let [ ingredients, setIngredients ] = useState("");
    let [ imagePC, setImagePC ] = useState(false);
    let [ imageMobile, setImageMobile ] = useState(false);
    let [ uploadExtFile, setUploadExtFile ] = useState('');
    let [ chooseTypeImageMobile, setChooseTypeImageMobile ] = useState(false);
    let [ iconsNormal, setIconsNormal ] = useState([]);
    let [ iconsSO, setIconsSO ] = useState([]);
    //calculator
    let [ autoOrManualTable, setAutoOrManualTable ] = useState("calc");
    let [ alcoholPercent, setAlcoholPercent ] = useState(0);
    let [ valueKJ, setValueKJ ] = useState(0); //wartosc energetyczna KJ na 100ml
    let [ valueKCAL, setValueKCAL ] = useState(0); //wartosc energetyczna KCAL na 100ml
    let [ valueFat, setValueFat ] = useState(0); // wartość tłuszczy w gramach na 100ml
    let [ valueFatSaturated, setValueFatSaturated ] = useState(0); // wartość tłuszczy nasyconych w gramach na 100ml
    let [ valueCarbohydrates, setValueCarbohydrates ] = useState(0); // wartość węglowodanów w gramach na 100ml
    let [ valueCarbohydratesSugars, setValueCarbohydratesSugars ] = useState(0); // wartość węglowodanów w tym cukry w gramach na 100ml
    let [ valueProtein, setValueProtein ] = useState(0); // wartość białka w gramach na 100ml
    let [ valueSalt, setValueSalt ] = useState(0); // wartość soli w gramach na 100ml
    //end calculator
    let relation = Get('relation');
    let langIDInUrl = Get('lang');
    let [ selectLangData, setSelectLangData ] = useState(false);
    let [ errorCalculator, setErrorCalculator ] = useState(false);

    useEffect(() => {
        if(!load && language && languages && languages.length > 0) {
            setLangID(language.id)
            if(relation) {
                getRelationData()
            }
            getLangInfo();
            setLoad(true);
        }
    }, [load, language, languages])


    const getLangInfo = () => {
        if(langIDInUrl) {
            let newSelectLangData = languages.filter(langData => langData.id == langIDInUrl ? langData : false);
            if(newSelectLangData && newSelectLangData.length > 0) {
                setSelectLangData(newSelectLangData[0]);
            }
        }else{
            setSelectLangData(language);
        }
    }

    const getRelationData = async() => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/getPageByID`,
            data: {
                token: localStorage.getItem('token'),
                pageID: relation
            }
        }).then(response => {
            let res = JSON.parse(response.request.response).data[0];
            setTitle(res.title);
            setResultPage(res);
            // setStatus(res.status);
            // if(res.product_info) {
            //     setProductInfo(res.product_info);
            // }
            // if(res.type_wine) {
            //     setTypeWine(res.type_wine);
            // }
            // if(res.ingredients) {
            //     setIngredients(res.ingredients);
            // }
            if(res.image_pc) {
                setImagePC(res.image_pc)
            }
            if(res.image_mobile) {
                setImageMobile(res.image_mobile);
            }
            if(res.iconsNormal) {
                setIconsNormal(res.iconsNormal);
            }
            if(res.iconsSO) {
                setIconsSO(res.iconsSO);
            }
            if(res.alcohol_percent) {
                setAlcoholPercent(res.alcohol_percent);
            }
            if(res.choose_type_image_mobile) {
                setChooseTypeImageMobile(res.choose_type_image_mobile)
            }
            if(res.value_kj) {
                setValueKJ(res.value_kj);
            }
            if(res.value_kcal) {
                setValueKCAL(res.value_kcal);
            }
            if(res.value_fat) {
                setValueFat(res.value_fat);
            }
            if(res.value_fat_saturated) {
                setValueFatSaturated(res.value_fat_saturated);
            }
            if(res.value_carbohydrates_sugars) {
                setValueCarbohydratesSugars(res.value_carbohydrates_sugars);
            }
            if(res.value_carbohydrates) {
                setValueCarbohydrates(res.value_carbohydrates);
            }
            if(res.value_protein) {
                setValueProtein(res.value_protein)
            }
            if(res.value_salt) {
                setValueSalt(res.value_salt);
            }
            if(res.auto_or_manual_table) {
                setAutoOrManualTable(res.auto_or_manual_table);
            }
        })
    }

    let validateForm = async() => {
        await setForceValidate(true);
        await wait(300);
        await setForceValidate(false);
        let errors = await checkErrors();
        if ( !errors ) {
            await save();
        } 
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.sections-component .error');
        let elementsCard = document.querySelectorAll('.new-page-container .error');

        if( elements.length > 0 ) {
            elements[0].closest('.content').classList.add("active");
            readTextNvda("sections");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else if(elementsCard.length > 0) {
            readTextNvda('new-page');
            let topElement = elementsCard[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = () => {
        if(!blockedBtn) {
            setBlockedBtn(true);
            // icons = filterData(icons, "icons");
            axios({
                method: 'post',
                url: `${ApiUrl()}/savePage`,
                data: {
                    token: localStorage.getItem('token'),
                    title: title,
                    productInfo, productInfo,
                    typeWine: typeWine,
                    ingredients: ingredients,
                    imageMobile: chooseTypeImageMobile == "normal" ? imageMobile.id : imageMobile,
                    imagePC: imagePC.id,
                    uploadExtFile: uploadExtFile,
                    alcoholPercent: alcoholPercent.length == 0 ? 0 : alcoholPercent,
                    iconsSO: iconsSO,
                    iconsNormal: iconsNormal,
                    chooseTypeImageMobile: chooseTypeImageMobile,
                    valueKJ: valueKJ.length == 0 ? 0 : valueKJ,
                    valueKCAL: valueKCAL.length == 0 ? 0 : valueKCAL,
                    valueFat: valueFat.length == 0 ? 0 : valueFat,
                    valueFatSaturated: valueFatSaturated.length == 0 ? 0 : valueFatSaturated,
                    valueCarbohydratesSugars: valueCarbohydratesSugars.length == 0 ? 0 : valueCarbohydratesSugars,
                    valueProtein: valueProtein.length == 0 ? 0 : valueProtein,
                    valueSalt: valueSalt.length == 0 ? 0 : valueSalt,
                    valueCarbohydrates: valueCarbohydrates.length == 0 ? 0 : valueCarbohydrates,
                    userID: localStorage.getItem('userId'),
                    postType: postType,
                    relation: Get('relation'),
                    language: Get('lang') ? Get('lang') : langID,
                    categoriesItems: categoriesArray,
                    autoOrManualTable: autoOrManualTable,
                    status: status,
                    selectedCompany: selectedCompany,
                    edit: false
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status = "success") {
                    setBlockedBtn(false);
                    if(!Get('relation')) {
                        incrementCountData()
                    }
                    props.history.push(`/dashboard/${postType}/page/${res.pageID}`)
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Etykieta została utworzona</p>` }) 
                }else{
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                }
            })
        }
    }

    const incrementCountData = () => {
        let count = countData[postType];
        let newCountData = countData;
        newCountData[postType] = ++count;
        dispatch({ type: 'COUNT_DATA', value: newCountData })
    }

    const insertCategories = (data) => {
        let newCategoryArray = [];
        data && data.map(item => {
            newCategoryArray = [...newCategoryArray, item];
        })
        setCategoriesArray(newCategoryArray);
    }

    let keyPressed = (e, action) => {
        if ( e.code === "Enter" ) {
            action();
        }
    }

    const options = [
        {
            value: "none",
            text: "jak dla desktop"
        },
        {
            value: "normal",
            text: "inne bez kadrowania"
        },
        {
            value: "cover",
            text: "inne kadruj"
        },
    ]

    const optionsCalculator = [
        {
            value: "calc",
            text: "kalkulator"
        },
        {
            value: "manual",
            text: "dane z analizy"
        }
    ]

    const onBeforeFileLoad = (elem) => {
		let type = elem.target.files[0].type;
		type = type.split('/')[1];
		setUploadExtFile(type == 'jpeg' ? 'jpg' : type);
	};

    useEffect(() => {
        if(load && autoOrManualTable == "calc") {
            calculated();
        }
    }, [valueFat, valueFatSaturated, valueCarbohydrates, valueCarbohydratesSugars, valueProtein, alcoholPercent, autoOrManualTable])

    const calculated = () => {
        let newValueKJ, fatKJ, carbohydratesKJ, proteinKJ, alcoholKJ = 0;
        //kJ
        //węglowodany - 17kj/g || bialko - 17kj/g || tluszcz - 37kj/g || alco - 29Kj/g
        fatKJ = valueFat * 37;
        carbohydratesKJ = valueCarbohydrates * 17;
        proteinKJ = valueProtein * 17;
        alcoholKJ = alcoholPercent * 29 * 0.78924;
        newValueKJ = fatKJ + carbohydratesKJ + proteinKJ + alcoholKJ;
        newValueKJ = Math.round(newValueKJ);
        setValueKJ(newValueKJ)
        //KCAL
        //węglowodany - 4kcal/g || bialko - 4kcal/g || tluszcz - 9kcal/g || alco - 7kcal/g
        let newValueKCAL, fatKCAL, carbohydratesKCAL, proteinKCAL, alcoholKCAL = 0;
        fatKCAL = valueFat * 9;
        carbohydratesKCAL = valueCarbohydrates * 4;
        proteinKCAL = valueProtein * 4;
        alcoholKCAL = alcoholPercent * 7 * 0.78924;
        newValueKCAL = fatKCAL + carbohydratesKCAL + proteinKCAL + alcoholKCAL;
        newValueKCAL = Math.round(newValueKCAL);
        setValueKCAL(newValueKCAL);
    }

    const toggleIcons = (icon, type) => {
        if(type == "normal") {
            let copyIcons = iconsNormal;
            if(copyIcons.includes(icon)) {
                copyIcons = copyIcons.filter(( item, i ) => item == icon ? false : item );
            }else{
                copyIcons = [...copyIcons, icon];
            }
            setIconsNormal(copyIcons);
        }else if(type == "so") {
            let copyIcons = iconsSO;
            if(copyIcons.includes(icon)) {
                copyIcons = copyIcons.filter(( item, i ) => item == icon ? false : item );
            }else{
                copyIcons = [...copyIcons, icon];
            }
            setIconsSO(copyIcons);
        }
    }

    const checkVal = (val) => {
        val = val.replace(",", ".");
        console.log(val);
        return val;
    }

    useEffect(() => {
        if(status == "opublikowana") {
            if((valueKCAL.length == 0 || valueKCAL == "0") || (valueKJ.length == 0 || valueKJ == "0") || (valueCarbohydrates.length == 0 || valueCarbohydrates == "0")) {
                setErrorCalculator(true);
            }else{
                setErrorCalculator(false);
            }
        }
    }, [forceValidate, valueKCAL, valueKJ, valueCarbohydrates])

    return (
        <div className="container new-page-container">
            <PageTitle title={"Utwórz etykietę"} langData={selectLangData} subTitle="Uzupełnij dane" />
            <div className="wrapper">
                <div className="form">
                    <div className="input-row margin-top-none">
                        <Input  name="title"
                            required={ true }
                            label="Nazwa handlowa produktu"
                            type="text" 
                            value={ title }
                            disabled={ false } 
                            setFunction={ (val) => setTitle(val) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    <div className="input-row">
                        <label>Zdjęcie butelki - desktop (opcjonalnie)</label>
                        <MediaLoad withRemove required={false} forceValidate={ forceValidate } setFunction={ val => setImagePC(val) } value={ imagePC } />
                    </div>
                    <div className="input-row">
                        <Select name="img-type"
                            data={ options } 
                            label={ "Zdjęcie butelki dla wersji mobilnej" }
                            value={ chooseTypeImageMobile }
                            handle={ (val) => { setChooseTypeImageMobile(val); setImageMobile(false) } } 
                            // reset={ reset }
                            class="btn-primary btn-simple" />
                    </div>
                    {chooseTypeImageMobile == "normal" &&
                        <div className="input-row margin-top-none">
                            <MediaLoad withRemove required={true} forceValidate={ forceValidate } setFunction={ val => setImageMobile(val) } value={ imageMobile } />
                        </div>
                    }
                    {chooseTypeImageMobile == "cover" &&
                        <div className="input-row margin-top-none">
                            <div className="upload-file">
                                <Avatar
                                    exportSize="237"
                                    onCrop={(preview) => setImageMobile(preview)}
                                    onClose={() => setImageMobile(false)}
                                    onBeforeFileLoad={(elem) => onBeforeFileLoad(elem)}
                                />
                            </div>
							<div className="avatar">{imageMobile && <img src={imageMobile} />}</div>
                        </div>
                    }
                    <div className="input-row">
                        <Input  name="type-wine"
                            required={ status == "opublikowana" ? true : false }
                            label="Nazwa środka spożywczego (kategoria produktu)"
                            type="text" 
                            value={ typeWine }
                            disabled={ false } 
                            setFunction={ (val) => setTypeWine(val) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    <div className="input-row">
                        <Editor required={false} forceValidate={ forceValidate } setFunction={ val => setProductInfo(val) } value={ productInfo } label="Dodatkowe dane szczegółowe (opcjonalnie)" desc="Uwaga! Przepisy zabraniają umieszczania na e-etykiecie informacji przeznaczonych do celów sprzedaży lub marketingu." />
                    </div>
                    <div className="input-row">
                        <Editor required={status == "opublikowana" ? true : false} forceValidate={ forceValidate } setFunction={ val => setIngredients(val) } value={ ingredients } label="Składniki" desc="Podaj składniki w malejącej kolejności ich masy w momencie użycia składników przy wytwarzaniu tego środka spożywczego. W przypadku substancji dodatkowych i aromatyzujących konieczne jest przy tym podanie funkcji np. substancja konserwująca, barwnik, aromat. Oznacz alergeny." />
                    </div>
                    <div className="input-row">
                        <Select name="img-type"
                            data={ optionsCalculator } 
                            label={ "Wartość odżywcza" }
                            desc={ "Sposób określenia wartości energetycznej" }
                            value={ autoOrManualTable }
                            handle={ (val) => setAutoOrManualTable(val) } 
                            // reset={ reset }
                            class="btn-primary btn-simple" />
                    </div>
                    {autoOrManualTable == "calc" &&
                        <div className="input-row margin-top-none">
                            <Input  name="alco-percent"
                                required={ false }
                                label="Rzeczywista zawartość objętościowa alkoholu (do kalkulatora)"
                                type="text" 
                                value={ alcoholPercent }
                                disabled={ false } 
                                setFunction={ (val) => setAlcoholPercent(checkVal(val)) } 
                                forceValidate={ forceValidate }
                                class="btn-primary btn-simple" />
                        </div>
                    }
                    <div className="input-row calculator border margin-top-none">
                        {/* <label>Kalkulator</label>
                        <small>Dane z analizy</small> */}
                        <div className="table-wrapper">
                            <div className="table">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col">w 100 ml</div>
                                    {/* <div className="col">w 125 ml</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Wartość energetyczna</div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} value={valueKJ} onChange={(e) => autoOrManualTable == "manual" && setValueKJ(checkVal(e.target.value))} disabled={autoOrManualTable == "calc" ? true : false} />kJ<br /><input type={autoOrManualTable == "manual" ? "text" : "number"}value={valueKCAL} onChange={(e) => autoOrManualTable == "manual" && setValueKCAL(checkVal(e.target.value))} disabled={autoOrManualTable == "calc" ? true : false} />kcal</div>
                                    {/* <div className="col"><input type="number" value={Math.round(valueKJ * 1.25)} disabled />kJ<br /><input type="number" value={Math.round(valueKCAL * 1.25)} disabled />kcal</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Tłuszcz<small>w tym kwasy tłuszczowe nasycone</small></div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} value={valueFat} onChange={(e) => setValueFat(checkVal(e.target.value))} />g<br /><input type={autoOrManualTable == "manual" ? "text" : "number"} value={valueFatSaturated} onChange={(e) => setValueFatSaturated(checkVal(e.target.value))} />g</div>
                                    {/* <div className="col"><input type="number" value={valueFat * 1.25} disabled />g<br /><input type="number" value={valueFatSaturated * 1.25} disabled />g</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Węglowodany<br /><small>w tym cukry</small></div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} value={valueCarbohydrates} onChange={(e) => setValueCarbohydrates(checkVal(e.target.value))} />g<br /><input type={autoOrManualTable == "manual" ? "text" : "number"} value={valueCarbohydratesSugars} onChange={(e) => setValueCarbohydratesSugars(checkVal(e.target.value))} />g</div>
                                    {/* <div className="col"><input type="number" value={valueCarbohydrates * 1.25} disabled />g<br /><input type="number" value={valueCarbohydratesSugars * 1.25} disabled />g</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Białko</div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} value={valueProtein} onChange={(e) => setValueProtein(checkVal(e.target.value))} />g</div>
                                    {/* <div className="col"><input type="number" value={valueProtein * 1.25} disabled />g</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Sól</div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} value={valueSalt} onChange={(e) => setValueSalt(checkVal(e.target.value))} />g</div>
                                    {/* <div className="col"><input type="number" value={valueSalt * 1.25} disabled />g</div> */}
                                </div>
                            </div>
                        </div>
                        {errorCalculator &&
                            <span class="error-text error">Należy wypełnić dane, by obliczyć wartość energetyczną.</span>
                        }
                    </div>
                    <div className="input-row border">
                        <div className="icons-wrapper">
                            {icons && icons.normal && icons.normal.map((icon, i) => (
                                <div className={`icon-box ${iconsNormal.includes(icon) ? 'active' : ''}`} key={i} onClick={() => toggleIcons(icon, "normal") }>
                                    <div className="icon">
                                        <img src={icon} alt={"Ikona"} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="icons-wrapper">
                            {icons && icons.so && icons.so.map((icon, i) => (
                                <div className={`icon-box ${iconsSO.includes(icon) ? 'active' : ''}`} key={i} onClick={() => toggleIcons(icon, "so") }>
                                    <div className="icon">
                                        <img src={icon} alt={"Ikona"} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="input-row save-row">
                        <Button title="Zapisz" setFunction={() => validateForm() } />
                    </div>
                </div>
                <div className="boxes">
                    <StatusPage status={status} updateStatus={(val) => setStatus(val) } />
                    
                    {/* {postType == "labels" && resultPage && categories && categories.length > 0 && categories.map((cat, index) => {
                        if(cat.name == "Kategorie") {
                            return (
                                // languagePageID={Get('lang') ? Get('lang') : langID}
                                <Categories postType={postType} languagePageID={false} meta={resultPage && resultPage.meta} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={forceValidate} />
                            )
                        }
                    })} */}
                    <div className="btn-fix" tabIndex="0" aria-label="Zapisz etykiete" onClick={() => validateForm()} onKeyDown={(e) => keyPressed(e, validateForm)}>
                        <SaveIcon />
                    </div>
                </div>
            </div>
        </div>       
    );
}

export default NewPage;

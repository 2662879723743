import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import GeneratePdfQrcode from './../generate-pdf-qrcode';

const QrCode = ( props ) => {

    const download = () => {
        let url = props.url;
        let fileName = url.substring(url.lastIndexOf('/') + 1);
        axios({
            method: 'get',
            url: `${ApiUrl()}/getSource?url=${url}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = new Blob([response.data], {type: 'image/svg+xml'});
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(res);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        })
    }

    return (
        <div className={"image-post settings-box"}>
            <div className="header">
                <span>QR CODE</span>
            </div>
            <div className="content">
                <div class="download" onClick={() => download()}>
                    <img src={props.url} alt="qr code" />
                </div>
                {/* <div class="download" onClick={() => downloadQrCodePDF()}>
                    Pobierz QR CODE w PDF
                </div> */}
                <div className="download" onClick={() => download()}>
                    <p>Pobierz w SVG</p><i className="material-icons">download</i>
                </div>
                <div className="download">
                    <GeneratePdfQrcode url={props.url} title={props.title} />
                </div>
            </div>
        </div>
    );
}

export default QrCode;
 
import React, { useState, useEffect } from 'react'
import EditIcon from '../../svg-components/EditIcon';
import AddIcon from '../../svg-components/AddIcon';
import { useSelector, useDispatch } from 'react-redux'

const LanguagesSettings = ( props ) => {
    let languages = useSelector(state => state.languages);
    let [ actualLang, setActualLang ] = useState(false);
    let pageData = props.page;

    useEffect(() => {
        if(pageData && languages) {
            getActualLang();
        }
    }, [languages, pageData])

    const getActualLang = () => {
        languages.filter(lang => lang.id == props.page.language.id ? setActualLang(lang) : null)
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const LanguagesBox = () => {
        let result = languages && actualLang && languages.map((lang, i) => {
            if(lang.id !== actualLang.id) {
                return (
                    <div className="language" key={lang.id}>
                        <img src={lang.flag_icon} alt={lang.name} title={lang.name} />
                        <CheckIcon langID={lang.id} langName={lang.name} />
                        <span>{lang.name}</span>
                    </div>
                )
            }
        })
        return result;
    }

    const CheckIcon = (data) => {
        let icon;
        let result = props.page && props.page.relations && props.page.relations.filter((page, index) => page.data.language == data.langID ? page : null)
        if(result && result.length > 0) {
            icon = <div className="icon" 
                onClick={() => props.changePage(`/dashboard/${props.postType}/page/${result[0].data.id}`, data)}
                aria-label="Edytuj relacje"
                tabIndex="0"
                role="button"
                onKeyDown={(e) => handleKeyDown(e, props.changePage, `/dashboard/${props.postType}/page/${result[0].data.id}`, data)}><EditIcon /></div>;
        }else{
            icon = <div className="icon" 
                onClick={() => props.changePage(`/dashboard/${props.postType}/new/?relation=${props.pageID}&lang=${data.langID}`, data)}
                aria-label="Dodaj relacje"
                tabIndex="0"
                role="button"
                onKeyDown={(e) => handleKeyDown(e, props.changePage, `/dashboard/${props.postType}/new/?relation=${props.pageID}&lang=${data.langID}`, data)}><AddIcon /></div>;
        }

        return icon;
    }

    return (
        <div className="languages-settings settings-box">
            <div className="header">
                <span>Języki</span>
            </div>
            <div className="content">
                {actualLang &&
                <div className="actual">
                    <span>Aktualny język</span>
                    <img src={actualLang.flag_icon} alt="aktualny język" title={actualLang.name} />
                </div>
                }
                <div className="other">
                    <div className="languages-items">
                        <LanguagesBox />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LanguagesSettings;

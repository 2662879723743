const WebUrl = () => {

    let link = '';
    if ( window.location.hostname === 'localhost') {
        link = 'http://localhost:3000'
    }else{
        link = 'https://e-etykieta.com'
    }

    return link;
}

export default WebUrl;
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PagesIcon from './../../../svg-components/PagesIcon';
import InformationCard from '../../../components/information-card/InformationCard';
import Button from './../../../components/button/Button';
import Table from './../../../components/table/Table';
import axios from 'axios'
import ApiUrl from './../../../services/ApiUrl';
import PageTitle from './../../../components/page-title/PageTitle';
import DownloadLabelsXlsx from './../../../components/download-labels-xlsx';

const Pages = (props) => {
    let [ load, setLoad ] = useState(false);
    let countData = useSelector(state => state.countData);
    let lang = useSelector(state => state.lang);
    let selectedCompany = useSelector(state => state.selectedCompany);
    let [ countPostType, setCountPostType ] = useState(0);
    let [ countPostTypeByLang, setCountPostTypeByLang ] = useState(0);
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[postType.length -1]
    const dispatch = useDispatch();

    useEffect(() => {
        if(countData) {
            // if(postType == "labels") {
            //     setCountPostTypeByLang(countData[postType + "-" + lang.id])
            // }
            setCountPostType(countData[postType])
        }
    }, [countData, postType, lang])

    const goToPage = () => {
        props.history.push(`/dashboard/${postType}/new`)
    }

    const removeItem = (ID) => {
        let url = `${ApiUrl()}/trashPage`
        if(postType == "trash") {
            url = `${ApiUrl()}/removePage`;
        }
        axios({
            method: 'post',
            // url: `${ApiUrl()}/removePage`,
            url: url,
            header: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: {
                userID: localStorage.getItem('userId'),
                token: localStorage.getItem('token'),
                removePageID: ID,
                selectedCompany: selectedCompany,
            }
        }).then(response => {
            let results = JSON.parse(response.request.response);
            if(results.status == false) {
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
            }
            decrementCountData();
        });
    }

    const decrementCountData = () => {
        let count = --countData[postType];
        let newCountData = countData;
        newCountData[postType] = count;
        //count type labels by lang
        // if(postType == "labels") {
        //     let newCt = --countData[postType + "-" + lang.id];
        //     newCountData[postType + "-" + lang.id] = newCt;
        //     setCountPostTypeByLang(newCt)
        // }
        if(postType == "labels") {
            let newCt = ++countData["trash"];
            newCountData["trash"] = newCt;
        }
        // else if(postType == "trash") {
        //     let newCt = countData["labels"];
        //     newCountData["labels"] = newCt;
        // }
        setCountPostType(count)
        dispatch({ type: 'COUNT_DATA', value: newCountData })
    }

    return (
        <div className="container pages-container">
            <PageTitle title={postType == "articles" ? "Wpisy" : "Etykiety"} />
            <div className="wrapper">
                <div className="actions xlsx">
                    {postType == "labels" &&
                        // <div class="download" aria-label="Pobierz excel" role="button" tabindex="0">Pobierz Etykiety do pliku (XLSX)
                        //     <i class="material-icons">download</i>
                        // </div>
                        <DownloadLabelsXlsx />
                    }
                </div>
                <div className="actions">
                    <div className="count">
                        <InformationCard text={`<p>Wszystkich etykiet: <strong>${countPostType ? countPostType : 0}</strong></p>`} icon={<PagesIcon />} />
                    </div>
                    <div className="btn-box">
                        <Button class="btn-secondary" title="Dodaj nową etykietę" icon="add" setFunction={() => goToPage()} />
                    </div>
                </div>
                <div className="table-result">
                    <Table dbTable="pages" langID={lang.id} postType={postType} removeItem={(ID) => removeItem(ID)} decrementCountData={() => decrementCountData()} />
                </div>
            </div>
        </div>       
    );
}

export default Pages;

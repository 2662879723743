import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TranslatesIcon from './../../../svg-components/TranslatesIcon';
import InformationCard from '../../../components/information-card/InformationCard';
import Button from './../../../components/button/Button';
import Table from './../../../components/table/Table';
import axios from 'axios'
import ApiUrl from './../../../services/ApiUrl';
import PageTitle from './../../../components/page-title/PageTitle';

const Translates = (props) => {
    let countData = useSelector(state => state.countData);
    let lang = useSelector(state => state.lang);
    let [ countPostType, setCountPostType ] = useState(0);
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[postType.length -1]
    const dispatch = useDispatch();

    useEffect(() => {
        if(countData) {
            setCountPostType(countData[postType])
        }
    }, [countData, postType])

    const goToPage = () => {
        props.history.push(`/dashboard/${postType}/new`)
    }

    const removeItem = (ID) => {
        // axios({
        //     method: 'post',
        //     url: `${ApiUrl()}/removeCompanies`,
        //     header: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*'
        //     },
        //     data: {
        //         userID: localStorage.getItem('userId'),
        //         token: localStorage.getItem('token'),
        //         removePageID: ID,
        //     }
        // }).then(response => {
        //     let results = JSON.parse(response.request.response);
        //     if(results.status == false) {
        //         dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
        //     }
        //     decrementCountData();
        // });
    }

    const decrementCountData = () => {
        let count = --countData[postType];
        let newCountData = countData;
        newCountData[postType] = count;
        setCountPostType(count)
        dispatch({ type: 'COUNT_DATA', value: newCountData })
    }

    return (
        <div className="container translates-container">
            <PageTitle title="Tłumaczenia" />
            <div className="wrapper">
                <div className="actions">
                    <div className="count">
                        <InformationCard text={`<p>Wszystkich firm: <strong>${countPostType ? countPostType : 0}</strong></p>`} icon={<TranslatesIcon />} />
                    </div>
                    <div className="btn-box">
                        <Button class="btn-secondary" title="Dodaj nową firme" icon="add" setFunction={() => goToPage()} />
                    </div>
                </div>
                <div className="table-result">
                    <Table dbTable="companies" langID={lang.id} postType={postType} removeItem={(ID) => removeItem(ID)} />
                </div>
            </div>
        </div>       
    );
}

export default Translates;

import React, { useState, useEffect, useRef} from "react"
import { Editor } from '@tinymce/tinymce-react';
import MediaLoadEditor from './../../components/media-load/MediaLoadEditor';

export default props => {
    let [error, setError] = useState(false);
    let [load, setLoad] = useState(false);
    let [errorText, setErrorText] = useState(false);
    let [ openMediaLoad, setOpenMediaLoad ] = useState(false);
    const editorRef = useRef(null);

    useEffect(() => {
        if (!load) {
            // checkDialog();
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if (load) {
            validate();
        }
    }, [props.forceValidate, props.required])

    // const checkDialog = () => {
        
    //     let dialog = setInterval(function() { 
    //         let dialogHTML = document.querySelectorAll('.tox-dialog-wrap');
    //         if(dialogHTML) {
    //             let btns = document.querySelectorAll('.tox-dialog__footer-end .tox-button');
    //             if(btns && btns.length > 0) {
    //                 if(btns[0].textContent !== "Zamknij") {
    //                     btns[0].textContent = 'Zamknij'
    //                 }
    //                 if(btns[1].textContent !== "Zapisz") {
    //                     btns[1].textContent = 'Zapisz'
    //                 }
    //             }
    //         }
    //     }, 500);
    // }

    const validate = () => {
        if (props.required) {
            let value = '';
            if(props.value && (props.value).length > 0) {
                value = props.value.replace(/<[^>]*>?/gm, '');
            }
            if (value && (value).length == 0 || !value) {
                setError(true)
                setErrorText('To pole jest wymagane.');
            } else {
                setError(false);
                setErrorText('');
            }
        }else{
            setErrorText('');
            setError(false);
        }
    }

    var toDateHtml = function (date) {
        return '<time datetime="' + date.toString() + '">' + date.toDateString() + '</time>';
    };

    // useEffect(() => {
    //     if(editorRef && editorRef.current) {
    //         console.log(editorRef.current.editor.ui)
    //         editorRef.current.editor.ui.registry.addButton('testBTN', {
    //             text: "My Button",
    //             onAction: function () {
    //                console.log('test'); // It's always empty
    //             }
    //          });
    //     }
    // }, [editorRef])

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    const replaceImg = (html) => {
        return replaceAll(html, "<p><img", "<p class='parent-img'><img");
    }

    const updateSrc = (imageData) => {
        console.log(imageData);
        let input = document.querySelectorAll('.tox-dialog .tox-form__controls-h-stack .tox-textfield');
        if(input) {
            console.log(input[0].value = imageData.src);
        }
    } 

    return (
        <div className={error ? "editor error" : "editor"} data-alert={errorText}>
            {props.label &&
                <label>{props.label}</label>
            }
            {props.desc &&
                <p>{props.desc}</p>
            }
            <Editor
                // onInit={(evt, editor) => editorRef.current = editor}
                ref={editorRef}
                disabled={props.disabled}
                initialValue={props.value ? props.value : ""}
                init={{
                    height: 250,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'code image',
                    ],
                    images_file_types: 'jpg,png',
                    file_picker_callback: (callback, value, meta) => {
                        // // Provide file and text for the link dialog
                        // if (meta.filetype == 'file') {
                        //   callback('mypage.html', { text: 'My text' });
                        // }
                    
                        // // Provide image and alt text for the image dialog
                        // if (meta.filetype == 'image') {
                        //   callback('myimage.jpg', { alt: 'My alt text' });
                        // }
                    
                        // // Provide alternative source and posted for the media dialog
                        // if (meta.filetype == 'media') {
                        //   callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
                        // }
                        setOpenMediaLoad(true);
                    },
                    language: "pl",
                    entity_encoding : "raw",
                    selector: "textarea",
                    valid_elements: "*[*]",
                    // toolbar: 'undo | redo | formatselect | blockquote | mark | formats | ' +
                    // 'bold | italic | alignleft | aligncenter | alignright | alignjustify | ' +
                    // 'bullist | numlist | outdent | indent | ' +
                    // 'removeformat | code | image',
                    toolbar: 'undo | redo | formatselect | formats | ' +
                    'bold | underline | italic | alignleft | aligncenter | alignright | alignjustify | ' +
                    'bullist | ' +
                    'code',
                    setup: function (editor) {
                        // editor.ui.registry.addToggleButton('mark', {
                        //    text: "Zajawka",
                        //    onAction: function (api) {
                        //     let status = api.isActive() ? false : true;
                        //     if(status) {
                        //         let select = editor.selection.getContent();
                        //         console.log(select)
                        //         // if(select) {
                        //             console.log('zaznaczam zaznaczone')
                        //             editor.insertContent(`<mark>${select}</mark>`);
                        //         // }
                        //         editor.execCommand('mceRemoveControl', false, 'mark');
                        //     }
                        //     api.setActive(!api.isActive());
                        //    }
                        // });
                        // editor.ui.registry.addButton('mark', {
                        //     text: "Zajawka",
                        //     onAction: function () {
                        //         editor.execCommand('mceRemoveControl', false, 'mark');
                        //         let select = editor.selection.getContent();
                        //         console.log(select)
                        //         if(select) {
                        //             console.log('zaznaczam zaznaczone')
                        //             editor.insertContent(`<h6>${select}</h6>`);
                        //         }
                        //         editor.execCommand('mceRemoveControl', false, 'mark');
                        //     }
                        //  });
                    },
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    init_instance_callback: function (editor) {
                        let allToolbar = document.querySelectorAll(".editor .tox-editor-header [tabIndex='-1']");
                        for(let i = 0; i<allToolbar.length; i++) {
                            allToolbar[i].setAttribute("tabIndex", "0");
                        }
                        // editor.formatter.apply('custom');
                        // editor.formatter.register('mark', { inline: 'mark', styles: { color: 'red' } });
                    }
                }}
                onBlur={(e) => props.setFunction(replaceImg(e.target.getContent())) }
            />
            {error && errorText &&
                <span className="error-text">{errorText}</span>
            }
            {openMediaLoad &&
                <MediaLoadEditor setFunction={ val => updateSrc(val) } setPopup={(status) => setOpenMediaLoad(status)} value={""} disableBtn={true} only="image" />
            }
        </div>
    )
}
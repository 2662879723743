const actualDate = () => {
    let today = new Date();
    let year = parseInt(today.getFullYear()) < 10 ? "0" + today.getFullYear() : today.getFullYear();
    let month = parseInt(today.getMonth()) < 10 ? "0" + today.getMonth() : today.getMonth();
    let day = parseInt(today.getDate()) < 10 ? "0" + today.getDate() : today.getDate();
    let hour = parseInt(today.getHours()) < 10 ? "0" + today.getHours() : today.getHours();
    let minute = parseInt(today.getMinutes()) < 10 ? "0" + today.getMinutes() : today.getMinutes();
    let second =  parseInt(today.getSeconds()) < 10 ? "0" + today.getSeconds() : today.getSeconds();
    let date = year+'-'+month+'-'+day+' '+hour+ ":" +minute+":" + second;
    return date;
}

export default actualDate;
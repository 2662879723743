import React from 'react';

const UsersIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="59px" height="41px">
            <path fill-rule="evenodd"  fill="rgb(44, 82, 153)"
            d="M21.140,25.689 C14.331,25.689 0.769,29.094 0.769,35.875 L0.769,40.967 L41.511,40.967 L41.511,35.875 C41.511,29.094 27.950,25.689 21.140,25.689 ZM7.579,35.147 C10.023,33.459 15.931,31.510 21.140,31.510 C26.349,31.510 32.256,33.459 34.701,35.147 L7.579,35.147 ZM21.140,20.597 C26.757,20.597 31.325,16.028 31.325,10.412 C31.325,4.795 26.757,0.226 21.140,0.226 C15.523,0.226 10.955,4.795 10.955,10.412 C10.955,16.028 15.523,20.597 21.140,20.597 ZM21.140,6.047 C23.555,6.047 25.505,7.996 25.505,10.412 C25.505,12.827 23.555,14.777 21.140,14.777 C18.725,14.777 16.775,12.827 16.775,10.412 C16.775,7.996 18.725,6.047 21.140,6.047 ZM41.627,25.864 C45.003,28.309 47.331,31.568 47.331,35.875 L47.331,40.967 L58.971,40.967 L58.971,35.875 C58.971,29.996 48.786,26.650 41.627,25.864 ZM38.601,20.597 C44.217,20.597 48.786,16.028 48.786,10.412 C48.786,4.795 44.217,0.226 38.601,0.226 C37.029,0.226 35.574,0.605 34.235,1.245 C36.069,3.835 37.145,7.007 37.145,10.412 C37.145,13.816 36.069,16.988 34.235,19.578 C35.574,20.218 37.029,20.597 38.601,20.597 Z"/>
        </svg>
    )
}

export default UsersIcon;
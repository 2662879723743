import React, { useState, useEffect } from 'react'
import Input from './../../../../components/input/Input';
import axios from 'axios';
import ApiUrl from './../../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'
import PageTitle from './../../../../components/page-title/PageTitle';
import Button from './../../../../components/button/Button';
import readTextNvda from './../../../../services/readTextNvda';

let valueTranslatesGlobal = [];
const Translate = (props) => {
    let [ load, setLoad ] = useState(false)
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ metaKey, setMetaKey ] = useState('');
    let [ valueTranslates, setValueTranslates ] = useState([]);
    let languages = useSelector(state => state.languages);
    let countData = useSelector(state => state.countData);
    const dispatch = useDispatch();
    let [forceValidate, setForceValidate ] = useState(false);

    useEffect(() => {
        if(!load && languages.length > 0) {
            createValueTranslates();
            setLoad(true)
        }
    }, [load, languages])

    const createValueTranslates = () => {
        let result = [];
        languages.map(lang => {
            let valueTranslate = {
                language: lang.shortcode,
                error: false,
                value: '',
            }
            result = [...result, valueTranslate];
        })
        setValueTranslates(result);
        valueTranslatesGlobal = result;
    }

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(!errors) {
            save();
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.new-translate-container .error');

        if( elements.length > 0 ) {
            readTextNvda("new-translate");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = () => {
        if(!blockedBtn) {
            setBlockedBtn(true);
            axios({
                method: 'post',
                url: `${ApiUrl()}/saveTranslate`,
                data: {
                    token: localStorage.getItem('token'),
                    valueTranslates: valueTranslates,
                    metaKey: metaKey,
                    userID: localStorage.getItem('userId'),
                    edit: false,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status = "success") {
                    setBlockedBtn(false);
                    incrementCountData()
                    props.history.push("/dashboard/translates/page/" + res.data[0].id)
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Dodano tłumaczenie</p>` }) 
                }else{
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                }
            })
        }
    }

    const incrementCountData = () => {
        let count = countData.translates;
        let newCountData = countData;
        newCountData.translates = ++count;
        dispatch({ type: 'COUNT_DATA', value: newCountData })
    }

    const updateValueTranslates = (val, index, label) => {
        let copyValueTranslates = valueTranslatesGlobal;
        copyValueTranslates = copyValueTranslates.map((item, i) => i == index ? ({...item, [label]: val}) : item);
        setValueTranslates(copyValueTranslates)
        valueTranslatesGlobal = copyValueTranslates;
    }

    return (
        <div className="container new-translate-container">
            <div className="wrapper">
                <PageTitle title="Dodaj nowe tłumaczenie:" subTitle="Uzupełnij dane" />
                <div className="form">
                    <div className="input-row">
                        <Input  name="meta-key"
                            required={ true }
                            label="Meta key"
                            type="text" 
                            value={ metaKey }
                            disabled={ false } 
                            setFunction={ (val) => setMetaKey(val.replace(' ', '_')) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    {valueTranslates && valueTranslates.length > 0 && valueTranslates.map((tran, index) => (
                        <div className="input-row">
                            <Input name="value"
                                required={true}
                                label={`Wartość ${tran.language}`}
                                type="text" 
                                value={ tran.value }
                                disabled={ false } 
                                setFunction={ (val) => updateValueTranslates(val, index, "value") } 
                                forceValidate={ forceValidate }
                                class="btn-primary btn-simple" />
                        </div>
                    ))}
                    <div className="input-save">
                        <Button title="Utwórz" setFunction={() => validate()} class="btn-secondary" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Translate;

import React from 'react';

const PagesIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="41px" height="41px">
            <path fill-rule="evenodd"  fill="rgb(44, 82, 153)"
            d="M34.526,10.789 L34.526,17.242 C34.526,17.242 30.232,17.263 30.210,17.242 L30.210,10.789 L23.737,10.789 C23.737,10.789 23.758,6.495 23.737,6.474 L30.210,6.474 L30.210,-0.000 L34.526,-0.000 L34.526,6.474 L41.000,6.474 L41.000,10.789 L34.526,10.789 ZM25.895,32.368 L8.631,32.368 L8.631,28.052 L25.895,28.052 L25.895,32.368 ZM8.631,15.105 L25.895,15.105 L25.895,19.421 L8.631,19.421 L8.631,15.105 ZM25.895,25.895 L8.631,25.895 L8.631,21.579 L19.421,21.579 L25.895,21.579 L25.895,25.895 ZM4.316,37.159 L30.210,37.159 L30.210,21.579 L34.526,21.579 L34.526,36.684 C34.526,39.058 32.584,41.000 30.210,41.000 L4.316,41.000 C1.942,41.000 0.000,39.058 0.000,36.684 L0.000,10.789 C0.000,8.416 1.942,6.474 4.316,6.474 L19.421,6.474 L19.421,10.789 L4.316,10.789 L4.316,37.159 Z"/>
        </svg>
    )
}

export default PagesIcon;
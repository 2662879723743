const readTextNvda = (type) => {
    let errorAlertItem;
    if(type == "settings") {
        errorAlertItem = document.querySelectorAll('.settings-container .error')[0];
        if(errorAlertItem) {
            errorAlertItem?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList?.add('active');
        }
    }else if(type == "sections") {
        errorAlertItem = document.querySelectorAll('.sections-component .error')[0];
        errorAlertItem?.parentElement?.parentElement?.parentElement?.parentElement.classList?.add('active');
        let slider = errorAlertItem.parentElement.parentElement.parentElement;
        let attachments = errorAlertItem.parentElement.parentElement.parentElement;
        let imageEditorLeftRight = errorAlertItem.parentElement.parentElement.parentElement;
        let autotest = errorAlertItem.parentElement.parentElement.parentElement;
        if(slider.classList.contains('slider-content')) {
            slider.children[1].classList.add('active');
        }else if(attachments.classList.contains('attachments-content')) {
            attachments.children[1].classList.add('active');
        }else if(imageEditorLeftRight.classList.contains('image-editor-content')) {
            imageEditorLeftRight.children[1].classList.add('active');
        }else if(autotest.classList.contains('autotest-content')) {
            autotest.children[1].classList.add('active')
        }
    }else if(type == "new-page") {
        errorAlertItem = document.querySelectorAll('.new-page-container .error')[0];
    }else if(type == "page") {
        errorAlertItem = document.querySelectorAll('.page-container .error')[0];
    }else if(type == "new-user") {
        errorAlertItem = document.querySelectorAll(`.new-user-container .error`)[0];
    }else if(type == "new-translate") {
        errorAlertItem = document.querySelectorAll('.new-translate-container .error')[0];
    }else if(type == "translate") {
        errorAlertItem = document.querySelectorAll('.translate-container .error')[0];
    }else if(type == "new-question") {
        errorAlertItem = document.querySelectorAll('.new-question-container .error')[0];
    }else if(type == "new-clinic") {
        errorAlertItem = document.querySelectorAll(`.new-clinic-container .error`)[0];
    }else if(type == "clinic") {
        errorAlertItem = document.querySelectorAll(`.clinic-container .error`)[0];
    }
    let text = errorAlertItem?.getAttribute('data-alert');
    let myAlert = document.createElement("p");
    myAlert.setAttribute("style", "z-index: -1; color: transparent;height:0");
    myAlert.setAttribute("role", "alert");
    let myAlertText = document.createTextNode(text);
    myAlert.appendChild(myAlertText);
    document.body.appendChild(myAlert);
    return true;
}

export default readTextNvda;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Input from './../../../components/input/Input';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import Button from './../../../components/button/Button';
import { connect } from 'react-redux';
import { setNotificationAlert } from './../../actions/index';

class SendResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            forceValidate: false,
            blocked: false,
        }
    }

    validate = async(e) => {
        await this.setState({ forceValidate: true });
        await this.wait(100);
        await this.setState({ forceValidate: false });
        let errors = await this.checkErrors();
        if(!errors) {
            this.sendResetEmail();
        }
    }

    wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.form-send-reset-password .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    sendResetEmail() {
        if(!this.state.blocked) {
            this.setState({ blocked: true })
            axios({
                method: 'post',
                url: `${ApiUrl()}/sendEmail`,
                data: {
                    email: this.state.email,
                    subject: 'Resetowanie hasła',
                    from: 'biuro@norddigital.com',
                    template: 'resetPassword',
                    linkPage: window.location.origin + "/login/reset-password",
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == "success") {
                    this.props.setNotificationAlert("<p>E-mail z linkiem do resetowania hasła został wysłany</p>")
                    this.setState({
                        email: ''
                    })
                }else if(res.status == "not_found") {
                    this.props.setNotificationAlert("<p>Wprowadzony e-mail nie istnieje w naszej bazie danych</p>")
                    this.setState({
                        email: ''
                    })
                }else{
                    this.props.setNotificationAlert("<p>Coś poszło nie tak, prosimy spróbować później</p>")
                }
            }).catch(e => {
                this.props.setNotificationAlert("<p>Coś poszło nie tak, prosimy spróbować później</p>")
            })
            this.setState({ blocked: false })
        }
    }
    
    render() {
        return (
            <div className="form-send-reset-password">
                <h1>Wprowadź swój adres e-mail, na który otrzymasz link resetujący hasło</h1>
                <form>
                    <Input  
                        required={ true }
                        setFunction={ val => this.setState({ email: val }) }
                        value={ this.state.email }
                        placeholder={ 'Wpisz adres email' }
                        regExp={ /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ }
                        minChar={ 6 } 
                        forceValidate={ this.state.forceValidate } 
                        onClickEnter={ () => this.validate() }
                    />
                    <Button title="Wyślij email resetujący hasło" setFunction={() => this.validate()} />
                </form>
                <div className="navigation">
                    <small><Link tabIndex="0" role="button" to="/login">Wróć do logowania</Link></small>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setNotificationAlert
}

const mapStateToProps = (state) => {
    return { }
};

export default connect(mapStateToProps, mapDispatchToProps)(SendResetPassword)
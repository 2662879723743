import React from 'react';

const TrashIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="21px" height="27px">
            <path fill-rule="evenodd"  fill="rgb(44, 82, 153)"
            d="M16.274,9.246 L16.274,24.041 L4.438,24.041 L4.438,9.246 L16.274,9.246 M14.055,0.369 L6.658,0.369 L5.178,1.849 L-0.000,1.849 L-0.000,4.808 L20.713,4.808 L20.713,1.849 L15.535,1.849 L14.055,0.369 ZM19.233,6.287 L1.479,6.287 L1.479,24.041 C1.479,25.668 2.811,27.000 4.438,27.000 L16.274,27.000 C17.902,27.000 19.233,25.668 19.233,24.041 L19.233,6.287 Z"/>
        </svg>
    )
}

export default TrashIcon;
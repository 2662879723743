import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {}

  render() {
    return (
        <div className="container-pdf">
            <a alt="QR CODE">
                <img src={this.props.qrcode} alt="QR CODE" />
            </a>
        </div>
    );
  }
}

const GeneratePdfQrcode = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: props.title ? props.title : "ETYKIETA",
    bodyClass: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? "firefox" : "other",
    pageStyle: navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? `@page { margin: 0 }` : `@page { margin: 30px 0 }`,
  });
  const onKeyDown = (e, functionName, functionValue = false, functionValueSecond = false) => {
    if (e.key === 'Enter') {
        functionName(functionValue, functionValueSecond)
    }
  }
  let qrcode = props.url;
  let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  
  return (
    <>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} qrcode={qrcode} isFirefox={isFirefox} />
      </div>
      <div className="action download" 
        onClick={ () => handlePrint() }
        role="button"
        tabIndex="0"
        onKeyDown={(e) => onKeyDown(e, handlePrint)}
        aria-label="Pobierz QR CODE w PDF"><p>Pobierz w PDF</p><i className="material-icons">download</i>
      </div>
    </>
  );
};

export default GeneratePdfQrcode;
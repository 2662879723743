import React, { useState,useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Header from './../../components/layout/header/Header';
import Navbar from './../../components/layout/navbar/Navbar';
import Welcome from './welcome/Welcome';
import Pages from './pages/Pages';
import Users from './users/Users';
import MediaLibrary from './media-library/MediaLibrary';
import Translates from './translates/Translates';
import NewUser from './users/new-user/NewUser';
import EditUser from './users/edit-user/EditUser';
import Translate from './translates/translate/Translate';
import NewTranslate from './translates/new-translate/NewTranslate';
import Page from './pages/page/Page';
import PageVersion from './pages/page/PageVersion';
import NewPage from './pages/new-page/NewPage';
import Categories from './categories/Categories';
import Tags from './tags/Tags';
import Companies from './companies/Companies';
import Company from './companies/company/Company';
import NewCompany from './companies/new-company/NewCompany';
import ApiUrl from '../../services/ApiUrl';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionsRoute } from '../../components/custom-routes/permissions.route';
import Loader from './../../components/loader/Loader';

let userData = [];
const DashboardView = (props) => {
    
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false);
    let [ loader, setLoader ] = useState(true);
    let selectedCompany = useSelector(state => state.selectedCompany);

    useEffect(() => {
        if (!load) {
            ;(async function() {
                await sessionUser();
                await getUser();
                await getLanguages();
                await getCategories();
                await getTags();
                await getCountData();
                await getIcons();
                await getCompanies();
                await setLoad(true);
                await setLoader(false);
            })()
        }
    },[load]);

    useEffect(() => {
        if(load && selectedCompany) {
            ;(async function() {
                await getCategories();
                await getTags();
                await getCountData();
            })()
        }
    }, [selectedCompany])

    const sessionUser = async () => {
        var currentUser = localStorage.getItem('token') ? true : false;
        if (!currentUser) {
            props.history.push({ pathname: '/login' });
        }
    }

    const getUser = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/me`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }

        }).then(response => {
            let res = JSON.parse(response.request.response);
            userData = res;
            dispatch({ type: 'USER_DATA', value: userData })
            getPermissions(res);
        });
    }

    const getLanguages = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getLanguages`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'LANGUAGES', value: res.data })
        })
    }

    const getCategories = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getCategories?userID=${userData.id}&selectedCompany=${selectedCompany}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'CATEGORIES', value: res.data })
        })
    }

    const getTags = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getTags?userID=${userData.id}&selectedCompany=${selectedCompany}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'TAGS', value: res })
        })
    }

    const getCountData = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/countData?userID=${userData.id}&selectedCompany=${selectedCompany}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'COUNT_DATA', value: res })
        });
    }

    const getIcons = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getIcons`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'ICONS_APP', value: res })
        });
    }

    const getCompanies = () => {
        if(userData.account_type.name == "Admin") {
            axios({
                method: 'get',
                url: `${ApiUrl()}/companiesListPagination?pageSize=${999}&simple=true`,
                headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
            }).then(response => {
                if(response.status == 200) {
                    let res = JSON.parse(response.request.response);
                    dispatch({ type: 'COMPANIES', value: res.data })
                }
            });
        }
    }
    
    const getPermissions = (data) => {
        let permissions = data.account_type.permissions
        let resultPermissions = [];
        if(permissions) {
            permissions.map(access => {
                if(access.action == "page-users") {
                    resultPermissions = [...resultPermissions, "page-users"];
                }else if(access.action == "page-library") {
                    resultPermissions = [...resultPermissions, "page-library"];
                }else if(access.action == "page-pages") {
                    resultPermissions = [...resultPermissions, "page-pages"];
                }else if(access.action == "page-translates") {
                    resultPermissions = [...resultPermissions, "page-translates"];
                }else if(access.action == "page-dashboard") {
                    resultPermissions = [...resultPermissions, "page-dashboard"];
                }else if(access.action == "page-categories") {
                    resultPermissions = [...resultPermissions, "page-categories"];
                }else if(access.action == "page-companies") {
                    resultPermissions = [...resultPermissions, "page-companies"];
                }else if(access.action == "page-trash") {
                    resultPermissions = [...resultPermissions, "page-trash"];
                }
            })
        }
        dispatch({ type: 'USER_PERMISSIONS', value: resultPermissions })
    }
    return (
        <>
            <div className="skip-link">
                <a href="#content-cms">Przejdź do głównej zawartości strony</a>
            </div>
            <Navbar location={props.location} />
            <main>
                <Header props={props} user={ userData } />
                <div className="content-cms" id="content-cms">
                    {loader &&
                        <div className="loader-wrapper loader-wrapper-full">
                            <div className="loader">
                                <Loader />
                            </div>
                        </div>
                    }
                    <Switch>
                        <PermissionsRoute path={`/dashboard`} exact component={ Welcome } props={ props } type="page-dashboard" />
                        <PermissionsRoute path={`/dashboard/profil`} exact component={ EditUser } props={ props } type="page-dashboard" />

                        <PermissionsRoute path={`/dashboard/users`} exact component={ Users } props={ props } type="page-users" />
                        <PermissionsRoute path={`/dashboard/users/new`} component={ NewUser } props={ props } type="page-users" />
                        <PermissionsRoute path={`/dashboard/users/user/:id`} component={ EditUser } props={ props } type="page-users" />

                        <PermissionsRoute path={`/dashboard/media-library`} exact component={ MediaLibrary } props={ props } type="page-library" />

                        <PermissionsRoute path={`/dashboard/translates`} exact component={ Translates } props={ props } type="page-translates" />
                        <PermissionsRoute path={`/dashboard/translates/page/:id`} exact component={ Translate } props={ props } type="page-translates" />
                        <PermissionsRoute path={`/dashboard/translates/new`} component={ NewTranslate } props={ props } type="page-translates" />

                        <PermissionsRoute path={`/dashboard/labels`} exact component={ Pages } props={ props } type="page-pages" />
                        <PermissionsRoute path={`/dashboard/labels/new`} component={ NewPage } props={ props } type="page-pages" />
                        <PermissionsRoute path={`/dashboard/labels/page/:id`} exact component={ Page } props={ props } type="page-pages" />
                        <PermissionsRoute path={`/dashboard/labels/versions/page/:id`} exact component={ PageVersion } props={ props } type="page-pages" />

                        <PermissionsRoute path={`/dashboard/trash`} exact component={ Pages } props={ props } type="page-trash" />
                        {/* <PermissionsRoute path={`/dashboard/labels/new`} component={ NewPage } props={ props } type="page-pages" /> */}
                        {/* <PermissionsRoute path={`/dashboard/labels/page/:id`} exact component={ Page } props={ props } type="page-pages" /> */}
                        
                        <PermissionsRoute path={`/dashboard/categories`} component={ Categories } props={ props } type="page-categories" />
                        <PermissionsRoute path={`/dashboard/tags`} component={ Tags } props={ props } type="page-categories" />

                        <PermissionsRoute path={`/dashboard/companies`} exact component={ Companies } props={ props } type="page-companies" />
                        <PermissionsRoute path={`/dashboard/companies/page/:id`} exact component={ Company } props={ props } type="page-translates" />
                        <PermissionsRoute path={`/dashboard/companies/new`} component={ NewCompany } props={ props } type="page-translates" />
                    </Switch>
                </div>
            </main>
        </>
    );
}

export default DashboardView;
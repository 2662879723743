import React from 'react';

const Button = props => {
    
    let keyPressed = (e) => {
        if ( e.code === "Enter" ) {
            props.setFunction()
        }
    }
    return (
        <div className="btn-wrap" 
            onClick={() => props.setFunction()}
            onKeyPress={ (e) => keyPressed(e)} 
            aria-hidden={props.block}
            role="button" aria-pressed="false" tabIndex={props.block ? "-1" : "0"} >
            <div className={"btn " + ( props.class ? props.class : 'btn-primary')}>
                {props.icon && props.icon == "add" &&
                    <div className="left-icon" aria-hidden={true}>
                        <p>+</p>
                    </div>
                }
                { props.title }
            </div>
        </div>
    )
}


export default Button;
import React, { useState, useEffect, useRef } from "react"
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import mp4Icon from './../../images/mp4.png';
import mp3Icon from './../../images/mp3.png';
import ytIcon from './../../images/yt.png';
import AttachmentIcon from './../../images/attachment.png';
import PlaceholderImg from './../../images/placeholder-img.png';
import FocusTrap from 'focus-trap-react';
import Loader from './../../components/loader/Loader';
import Button from './.././button/Button';
import { useSelector } from 'react-redux';

export default props => {
    let [ error, setError ] = useState( false );
    let [ load, setLoad ] = useState( false );
    let [ errorText, setErrorText ] = useState( false );
    let [ images, setImages ] = useState([]);
    let [ pageImage, setPageImage ] = useState(1);
    let [ lastPageImage, setLastPageImage ] = useState(false);
    let itemsOnPage = 30;
    let scrollRef = useRef();
    let [ loader, setLoader ] = useState(false);
    let [ selectedIndex, setSelectedIndex ] = useState([]);
    let selectedCompany = useSelector(state => state.selectedCompany);
    
    useEffect(() => {
        if ( !load ) {
            setLoad(true);
        }
    }, [load])
  
    useEffect(() => {
      if ( load ) {
          validate();
      }
    }, [props.forceValidate])

    const validate = () => {
        let error = false;
        if(props.value && (props.value).length == 0 || !props.value) {
            setError(true);
            setErrorText('Należy wybrać zdjęcie');
            error = true;
        }else{
            setError(false);
            setErrorText('');
            error = false;
        }

        if(props.setError) {
            props.setError(error);
        }
    }

    const getImages = () => {
        setLoader(true);
        let type = props.only ? props.only : null;
        axios({
            method: 'get',
            url: `${ApiUrl()}/galleryItemsPagination?currentPage=${pageImage}&type=${type}&pageSize=${itemsOnPage}&userID=${localStorage.getItem('userId')}&selectedCompany=${selectedCompany}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` },
        }).then(response => {
            let res = JSON.parse(response.request.response);
            let newImages = [...images, ...res.data]
            setImages(newImages)
            setLoad(false);
            if(!lastPageImage) {
                setLastPageImage(res.last_page);
            }
            if(props.type == "gallery") {
                checkImagesClass(newImages);
            }
            setLoader(false);
        })
    }

    useEffect(() => {
        if(images && images.length == 0) {
            getImages();
        }
    }, [])

    const scrollMedia = () => {
        let positionYScroll = scrollRef.current.scrollTop;
        let mediaBox = scrollRef.current;
        let maxScroll = mediaBox ? mediaBox.scrollHeight - mediaBox.offsetHeight : false;
        positionYScroll = Math.floor(positionYScroll);
        maxScroll = maxScroll ? Math.floor(maxScroll) : false;
        if(maxScroll) {
            if(positionYScroll == maxScroll) {
                if(lastPageImage > pageImage) {
                    setPageImage(++pageImage)
                    setLoader(true);
                    getImages();
                }
            }
        }
    }

    const action = (index, img, remove = false) => {
        if(remove) {
            setSelectedIndex([]);
            props.setFunction("");
        }
        if(!props.type) {
            props.setFunction(img); 
            props.setPopup(false)
        }else{
            if(selectedIndex.includes(index)) {
                let copySelectedIndex = selectedIndex;
                copySelectedIndex = copySelectedIndex.filter((item, i) => item !== index ? item : false);
                setSelectedIndex(copySelectedIndex);
                props.removeItem(images[index].id);
            }else{
                let copySelectedIndex = selectedIndex;
                copySelectedIndex = [...copySelectedIndex, index];
                setSelectedIndex(copySelectedIndex);
                props.setFunction(img);
            }
        }
    }

    const checkImagesClass = (allImages) => {
        let indexList = selectedIndex;
        allImages && allImages.map((image, index) => {
            props.items && props.items.map(item => {
                if(item.image && item.image.id == image.id) {
                    indexList = [...indexList, index];
                }
            })
        })
        setSelectedIndex(indexList);
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter' || e.key == 'Escape') {
            functionName(functionValue)
        }
    }

    const handleKeyDownSecond = (e, functionName, functionValue = false, functionValueSecond = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue, functionValueSecond)
        }
    }
  return (
    <div className={error ? "media-load error" : "media-load" + (" " + (props.value && props.value.src ? props.value.src.split('.').pop().substring(0, 3) : ""))} data-alert={errorText}>
        <FocusTrap>
            <div className="media-popup">
                {loader &&
                    <div className="loader-wrapper">
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                }
                <div className="close" 
                    tabIndex="0" 
                    role="button" 
                    onClick={() => props.setPopup(false)}
                    onKeyDown={(e) => handleKeyDown(e, props.setPopup, false)}>Zamknij</div>
                <div className="images-media" id="images-media" onScroll={() => scrollMedia()} ref={scrollRef}>
                    {images && images.map((img, index) => (
                        <div tabIndex="0" className={"image " + (img.src.split('.').pop().substring(0, 3)) + (selectedIndex.includes(index) ? " active" : "")} key={img.id} onClick={(e) => action(index, img) } onKeyDown={(e) => handleKeyDownSecond(e, action, index, img)}>
                            {(img.type == "image" || img.type == "vector") &&
                                <img src={img.sizes.small} alt={img.alt} title={img.name} />
                            }
                            {img.type == "video" &&
                                <img src={mp4Icon} className="bg-svg" alt={"mp4"} title={img.name} />
                            }
                            {img.type == "lector" &&
                                <img src={mp3Icon} className="bg-svg" alt={"mp3"} title={img.name} />
                            }
                            {img.type == "video-url" &&
                                <img src={ytIcon} className="bg-svg" alt={"youtube"} title={img.name} />
                            }
                            {img.type == "attachment" &&
                                <img src={AttachmentIcon} className="bg-svg" alt={"attachment"} title={img.name} />
                            }
                            <div className="simple-info-image">
                                <div className="info-row">
                                    <span className="label">Nazwa: </span>
                                    <span className="value">{img.name}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </FocusTrap>
    </div>
  )
}

import React, { useState, useEffect } from "react"
import Button from './../button/Button';
import Select from './../select/Select';
import Input from './../input/Input';

export default props => {
    let [ load, setLoad ] = useState( false );
    let [ btnNextBlock, setBtnNextBlock ] = useState(false);
    let [ btnPrevBlock, setBtnPrevBlock ] = useState(false);

    useEffect(() => {
        if(!load) {
            checkBlockBtns();
            setLoad(true)
        }
    }, [load])

    useEffect(() => {
        if(load) {
            checkBlockBtns();
        }
    }, [props])

    const checkBlockBtns = () => {
        let btnNext = props.maxPages > props.currentPage ? false : true;
        let btnPrev = 1 < props.currentPage ? false : true;
        setBtnNextBlock(btnNext);
        setBtnPrevBlock(btnPrev)
    }

    let rowsData = [
        {
            value: 10,
            text: "10 wyników",
            ariaLabel: "10 wyników"
        },
        {
            value: 20,
            text: "20 wyników",
            ariaLabel: "20 wyników"
        },
        {
            value: 30,
            text: "30 wyników",
            ariaLabel: "30 wyników"
        },
        {
            value: 50,
            text: "50 wyników",
            ariaLabel: "50 wyników"
        },
    ]

    return (
        <div className="container-pagination">
            <div className="wrapper">
                <div className="action">
                    <Button class={btnPrevBlock ? "btn-secondary block" : "btn-secondary"} block={btnPrevBlock ? true : false} title="Poprzednia" setFunction={ () => !btnPrevBlock && props.changeCurrentPage(props.currentPage - 1) } />
                </div>
                <div className="center-actions">
                    <div className="action">
                        <p>Strona</p>
                        <Input  
                            setFunction={ val => props.changeCurrentPage(val) }
                            value={ props.currentPage }
                            regExp={ /.*/ }
                            max={props.maxPages}
                            minChar={ 0 } 
                            ariaLabel={`Wpisz numer strony - maksymalna dostępna strona to ${props.maxPages}`}
                            type="number"
                        />
                        <p>z {props.maxPages}</p>
                    </div>
                    <div className="action">
                        <Select name="rows"
                            ariaLabel="Wybierz ile wyników ma być wyświetlane na jednej stronie"
                            data={ rowsData } 
                            value={ props.pageSize }
                            handle={ (size) => props.setPageSize(size) } />
                    </div>
                </div>
                <div className="action">
                    <Button class={btnNextBlock ? "btn-secondary block" : "btn-secondary"} block={btnNextBlock ? true : false} title="Następna" setFunction={ () => !btnNextBlock && props.changeCurrentPage(props.currentPage + 1) } />
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom";
import Input from './../../../../components/input/Input';
import axios from 'axios';
import ApiUrl from './../../../../services/ApiUrl';
import Tags from './../../../../components/boxes/Tags';
import { useSelector, useDispatch } from 'react-redux'
import Categories from './../../../../components/boxes/Categories';
import QrCode from './../../../../components/boxes/QrCode';
import Select from './../../../../components/select/Select';
import StatusPage from './../../../../components/boxes/StatusPage';
import WebUrl from './../../../../services/WebUrl';
import PageTitle from './../../../../components/page-title/PageTitle';
import Editor from './../../../../components/editor/Editor';
import MediaLoad from './../../../../components/media-load/MediaLoad';
import Avatar from 'react-avatar-edit';
import Popup from './../../../../components/popup/Popup';

const PageVersion = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    let [ load, setLoad ] = useState(false);
    let [ loader, setLoader ] = useState(true);
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ title, setTitle ] = useState('');
    let [ errorTitle, setErrorTitle ] = useState(false);
    let [ status, setStatus ] = useState(false);
    let [ resetLoad, setResetLoad ] = useState(0);
    let [ validateSections, setValidateSections ] = useState(0);
    let pageID = typeof window !== "undefined" && (window.location.pathname).split("/");pageID = pageID[pageID.length -1]
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[2]
    let [ resultPage, setResultPage ] = useState(false);
    let categories = useSelector(state => state.categories);
    let lang = useSelector(state => state.lang);
    let languages = useSelector(state => state.languages);
    let icons = useSelector(state => state.iconsApp);
    let selectedCompany = useSelector(state => state.selectedCompany);
    let [ date, setDate ] = useState();
    let [ categoriesArray, setCategoriesArray ] = useState([]);
    let [ pagePreviewUrl, setPagePreviewUrl ] = useState(false);
    let [ version, setVersion ] = useState(false);
    let [ allVersions, setAllVersions ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ lastClickedRadioCategory, setLastClickedRadioCategory ] = useState(false); 
    let [ resetVersion, setResetVersion ] = useState(false);
    //new
    let [ typeWine, setTypeWine ] = useState("");
    let [ productInfo, setProductInfo ] = useState("");
    let [ ingredients, setIngredients ] = useState("");
    let [ imagePC, setImagePC ] = useState(false);
    let [ imageMobile, setImageMobile ] = useState(false);
    let [ uploadExtFile, setUploadExtFile ] = useState('');
    let [ chooseTypeImageMobile, setChooseTypeImageMobile ] = useState(false);
    let [ iconsNormal, setIconsNormal ] = useState([]);
    let [ iconsSO, setIconsSO ] = useState([]);
    //calculator
    let [ autoOrManualTable, setAutoOrManualTable ] = useState(false);
    let [ alcoholPercent, setAlcoholPercent ] = useState(0);
    let [ valueKJ, setValueKJ ] = useState(0); //wartosc energetyczna KJ na 100ml
    let [ valueKCAL, setValueKCAL ] = useState(0); //wartosc energetyczna KCAL na 100ml
    let [ valueFat, setValueFat ] = useState(0); // wartość tłuszczy w gramach na 100ml
    let [ valueFatSaturated, setValueFatSaturated ] = useState(0); // wartość tłuszczy nasyconych w gramach na 100ml
    let [ valueCarbohydrates, setValueCarbohydrates ] = useState(0); // wartość węglowodanów w gramach na 100ml
    let [ valueCarbohydratesSugars, setValueCarbohydratesSugars ] = useState(0); // wartość węglowodanów w tym cukry w gramach na 100ml
    let [ valueProtein, setValueProtein ] = useState(0); // wartość białka w gramach na 100ml
    let [ valueSalt, setValueSalt ] = useState(0); // wartość soli w gramach na 100ml
    //end calculator
    let [ activePopup, setActivePopup ] = useState(false);
    let [ textPopup, setTextPopup ] = useState(false);
    let [ errorCalculator, setErrorCalculator ] = useState(false);

    useEffect(() => {
        if(!load && postType) {
            getPage();
            setLoad(true);
        }
    }, [load, postType])

    useEffect(() => {
        if(load) {
            getPage();
            setLoad(false);
        }
    }, [pageID])

    useEffect(() => {
        if(load) {
            validateInputs();
        }
    }, [validateSections])

    const getPage = async() => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/getPageVersionByID`,
            data: {
                token: localStorage.getItem('token'),
                pageID: pageID,
                selectedCompany: selectedCompany
            }
        }).then(response => {
            let res = JSON.parse(response.request.response)
            if(res.status == 'error') {
                history.push(`/dashboard`)
            }
            res = res;
            setTitle(res.title);
            setResultPage(res);
            setDate(new Date(res.created_at));
            setStatus(res.status);
            createPreviewUrl(res);
            let newIconsNormal = iconsNormal;
            let newIconsSO = iconsSO;
            res && res.meta_pages && res.meta_pages.map((meta, index) => {
                if(meta.type == "productInfo") {
                    setProductInfo(meta.value ? meta.value : "");
                }
                if(meta.type == "autoOrManualTable") {
                    setAutoOrManualTable(meta.value);
                }
                if(meta.type == "typeWine") {
                    setTypeWine(meta.value);
                }
                if(meta.type == "ingredients") {
                    setIngredients(meta.value);
                }
                if(meta.type == "imagePC") {
                    setImagePC(meta.value);
                }
                if(meta.type == "alcoholPercent") {
                    setAlcoholPercent(meta.value);
                }
                if(meta.type == "chooseTypeImageMobile") {
                    setChooseTypeImageMobile(meta.value);
                }
                if(meta.type == "valueKJ") {
                    setValueKJ(meta.value);
                }
                if(meta.type == "valueKCAL") {
                    setValueKCAL(meta.value);
                }
                if(meta.type == "valueFat") {
                    setValueFat(meta.value);
                }
                if(meta.type == "valueFatSaturated") {
                    setValueFatSaturated(meta.value);
                }
                if(meta.type == "valueCarbohydratesSugars") {
                    setValueCarbohydratesSugars(meta.value);
                }
                if(meta.type == "valueCarbohydrates") {
                    setValueCarbohydrates(meta.value);
                }
                if(meta.type == "valueProtein") {
                    setValueProtein(meta.value);
                }
                if(meta.type == "valueSalt") {
                    setValueSalt(meta.value);
                }
                if(meta.type == "imageMobile") {
                    setImageMobile(meta.value);
                }
                if(meta.type == "imageMobileAvatar") {
                    setImageMobile(meta.value);
                }
                if(meta.type == "iconsNormal") {
                    newIconsNormal = [...newIconsNormal, meta.value];
                    setIconsNormal(newIconsNormal);
                }
                if(meta.type == "iconsSO") {
                    newIconsSO = [...newIconsSO, meta.value];
                    setIconsSO(newIconsSO);
                }
            })
            setLoader(false);
            setResetLoad(generateRandomNumber(1, 99999999));
        })
    }

    const createPreviewUrl = (data) => {
        let url = ''
        let slug = data.slug;
        let key = "";
        key = `?ver=${data.preview_key}`;
        url = `${WebUrl()}/${slug}${key}`
        setPagePreviewUrl(url)
    }

    const generateRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
    };

    let validateInputs = () => {
        if(title.length == 0) {
            setErrorTitle(1);
        }else{
            setErrorTitle(false);
        }
    }

    const options = [
        {
            value: "none",
            text: "jak dla desktop"
        },
        {
            value: "normal",
            text: "inne bez kadrowania"
        },
        {
            value: "cover",
            text: "inne kadruj"
        },
    ]

    const optionsCalculator = [
        {
            value: "calc",
            text: "kalkulator"
        },
        {
            value: "manual",
            text: "dane z analizy"
        }
    ]

    const onBeforeFileLoad = (elem) => {
		let type = elem.target.files[0].type;
		type = type.split('/')[1];
		setUploadExtFile(type == 'jpeg' ? 'jpg' : type);
	};

    useEffect(() => {
        if(load && autoOrManualTable == "calc") {
            calculated();
        }
    }, [valueFat, valueFatSaturated, valueCarbohydrates, valueCarbohydratesSugars, valueProtein, alcoholPercent, autoOrManualTable])

    const calculated = () => {
        let newValueKJ, fatKJ, carbohydratesKJ, proteinKJ, alcoholKJ = 0;
        //kJ
        //węglowodany - 17kj/g || bialko - 17kj/g || tluszcz - 37kj/g || alco - 29Kj/g
        fatKJ = valueFat * 37;
        carbohydratesKJ = valueCarbohydrates * 17;
        proteinKJ = valueProtein * 17;
        alcoholKJ = alcoholPercent * 29 * 0.78924;
        newValueKJ = fatKJ + carbohydratesKJ + proteinKJ + alcoholKJ;
        newValueKJ = Math.round(newValueKJ);
        setValueKJ(newValueKJ)
        //KCAL
        //węglowodany - 4kcal/g || bialko - 4kcal/g || tluszcz - 9kcal/g || alco - 7kcal/g
        let newValueKCAL, fatKCAL, carbohydratesKCAL, proteinKCAL, alcoholKCAL = 0;
        fatKCAL = valueFat * 9;
        carbohydratesKCAL = valueCarbohydrates * 4;
        proteinKCAL = valueProtein * 4;
        alcoholKCAL = alcoholPercent * 7 * 0.78924;
        newValueKCAL = fatKCAL + carbohydratesKCAL + proteinKCAL + alcoholKCAL;
        newValueKCAL = Math.round(newValueKCAL);
        setValueKCAL(newValueKCAL);
    }

    const toggleIcons = (icon, type) => {
        if(type == "normal") {
            let copyIcons = iconsNormal;
            if(copyIcons.includes(icon)) {
                copyIcons = copyIcons.filter(( item, i ) => item == icon ? false : item );
            }else{
                copyIcons = [...copyIcons, icon];
            }
            setIconsNormal(copyIcons);
        }else if(type == "so") {
            let copyIcons = iconsSO;
            if(copyIcons.includes(icon)) {
                copyIcons = copyIcons.filter(( item, i ) => item == icon ? false : item );
            }else{
                copyIcons = [...copyIcons, icon];
            }
            setIconsSO(copyIcons);
        }
    }

    const checkVal = (val) => {
        val = val.replace(",", ".");
        console.log(val);
        return val;
    }

    useEffect(() => {
        if(status == "opublikowana") {
            if((valueKCAL.length == 0 || valueKCAL == "0") || (valueKJ.length == 0 || valueKJ == "0") || (valueCarbohydrates.length == 0 || valueCarbohydrates == "0")) {
                setErrorCalculator(true);
            }else{
                setErrorCalculator(false);
            }
        }
    }, [forceValidate, valueKCAL, valueKJ, valueCarbohydrates])

    return (
        <div className="container page-container">
            {resultPage &&
                <PageTitle title={`Przeglądasz archiwalną wersję etykiety (${resultPage.title})`} loader={loader} />
            }
            {textPopup &&
                <Popup 
                    close={() => setTextPopup(false)} 
                    action={() => setTextPopup(false)} 
                    btnText={"Ok"} 
                    editor={textPopup} 
                />
            }
            <div className="wrapper">
                <div className="form">
                    <div className="input-row">
                        <Input  name="title"
                            required={ true }
                            label="Nazwa handlowa produktu"
                            type="text" 
                            value={ title }
                            disabled={ true } 
                            setFunction={ (val) => setTitle(val) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    {resultPage && pagePreviewUrl &&
                        <div className="input-row row-slug margin-top-none">
                            <a href={pagePreviewUrl} target="_blank" tabIndex="0" aria-label={"Otwórz podgląd etykiety"} role="button">{pagePreviewUrl}</a>
                        </div>
                    }
                    <div className="input-row">
                        <label>Zdjęcie butelki - desktop (opcjonalnie)</label>
                        <MediaLoad disabled required={false} withRemove forceValidate={ forceValidate } setFunction={ val => setImagePC(val) } value={ imagePC } />
                    </div>
                    <div className="input-row">
                        <Select name="img-type"
                            data={ options } 
                            label={ "Zdjęcie butelki dla wersji mobilnej" }
                            value={ chooseTypeImageMobile }
                            handle={ (val) => { setChooseTypeImageMobile(val); setImageMobile(false) } } 
                            // reset={ reset }
                            disabled
                            class="btn-primary btn-simple" />
                    </div>
                    {chooseTypeImageMobile == "normal" &&
                        <div className="input-row margin-top-none">
                            <MediaLoad disabled withRemove required={true} forceValidate={ forceValidate } setFunction={ val => setImageMobile(val) } value={ imageMobile } />
                        </div>
                    }
                    {chooseTypeImageMobile == "cover" &&
                        <div className="input-row margin-top-none">
                            <div className="upload-file">
                                {/* <Avatar
                                    // width={390}
                                    // height={390}
                                    // width={500}
                                    // height={1500}
                                    disabled
                                    exportSize="237"
                                    onCrop={(preview) => setImageMobile(preview)}
                                    onClose={() => setImageMobile(false)}
                                    onBeforeFileLoad={(elem) => onBeforeFileLoad(elem)}
                                /> */}
                            </div>
                            <div className="avatar">{imageMobile && <img src={imageMobile} />}</div>
                        </div>
                    }
                    <div className="input-row">
                        <Input  name="type-wine"
                            required={ status == "opublikowana" ? true : false }
                            label="Nazwa środka spożywczego (kategoria produktu)"
                            type="text" 
                            value={ typeWine }
                            disabled={ true } 
                            setFunction={ (val) => setTypeWine(val) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    <div className="input-row">
                        <Editor required={false} disabled forceValidate={ forceValidate } setFunction={ val => setProductInfo(val) } value={ productInfo } label="Dodatkowe dane szczegółowe (opcjonalnie)" desc="Uwaga! Przepisy zabraniają umieszczania na e-etykiecie informacji przeznaczonych do celów sprzedaży lub marketingu." />
                    </div>
                    <div className="input-row">
                        <Editor required={status == "opublikowana" ? true : false} disabled forceValidate={ forceValidate } setFunction={ val => setIngredients(val) } value={ ingredients } label="Składniki" desc="Podaj składniki w malejącej kolejności ich masy w momencie użycia składników przy wytwarzaniu tego środka spożywczego. W przypadku substancji dodatkowych i aromatyzujących konieczne jest przy tym podanie funkcji np. substancja konserwująca, barwnik, aromat. Oznacz alergeny." />
                    </div>
                    <div className="input-row">
                        <Select name="img-type"
                            data={ optionsCalculator } 
                            label={ "Wartość odżywcza" }
                            desc={ "Sposób określenia wartości energetycznej" }
                            value={ autoOrManualTable }
                            handle={ (val) => setAutoOrManualTable(val) } 
                            // reset={ reset }
                            disabled
                            class="btn-primary btn-simple" />
                    </div>
                    {autoOrManualTable == "calc" &&
                        <div className="input-row margin-top-none">
                            <Input  name="alco-percent"
                                required={ false }
                                label="Rzeczywista zawartość objętościowa alkoholu (do kalkulatora)"
                                type="text" 
                                value={ alcoholPercent }
                                disabled={ true } 
                                setFunction={ (val) => setAlcoholPercent(checkVal(val)) } 
                                forceValidate={ forceValidate }
                                class="btn-primary btn-simple" />
                        </div>
                    }
                    <div className="input-row calculator border margin-top-none">
                        {/* <label>Kalkulator</label>
                        <small>Dane z analizy</small> */}
                        <div className="table-wrapper">
                            <div className="table">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col">w 100 ml</div>
                                    {/* <div className="col">w 125 ml</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Wartość energetyczna</div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} min="0" value={valueKJ} onChange={(e) => autoOrManualTable == "manual" && setValueKJ(checkVal(e.target.value))} disabled={true} />kJ<br /><input type={autoOrManualTable == "manual" ? "text" : "number"} min="0" value={valueKCAL} onChange={(e) => autoOrManualTable == "manual" && setValueKCAL(checkVal(e.target.value))} disabled={true} />kcal</div>
                                    {/* <div className="col"><input type="number" min="0"  value={Math.round(valueKJ * 1.25)} disabled />kJ<br /><input type="number" min="0" value={Math.round(valueKCAL * 1.25)} disabled />kcal</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Tłuszcz<small>w tym kwasy tłuszczowe nasycone</small></div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} min="0" value={valueFat} onChange={(e) => setValueFat(checkVal(e.target.value))} disabled={true} />g<br /><input type={autoOrManualTable == "manual" ? "text" : "number"} min="0" value={valueFatSaturated} onChange={(e) => setValueFatSaturated(checkVal(e.target.value))} disabled={true} />g</div>
                                    {/* <div className="col"><input type="number" min="0" value={valueFat * 1.25} disabled />g<br /><input type="number" min="0" value={valueFatSaturated * 1.25} disabled />g</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Węglowodany<br /><small>w tym cukry</small></div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} min="0" value={valueCarbohydrates} onChange={(e) => setValueCarbohydrates(checkVal(e.target.value))} disabled={true} />g<br /><input type={autoOrManualTable == "manual" ? "text" : "number"} min="0" value={valueCarbohydratesSugars} onChange={(e) => setValueCarbohydratesSugars(checkVal(e.target.value))} disabled={true} />g</div>
                                    {/* <div className="col"><input type="number" min="0" value={valueCarbohydrates * 1.25} disabled />g<br /><input type="number" min="0" value={valueCarbohydratesSugars * 1.25} disabled />g</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Białko</div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} min="0" value={valueProtein} onChange={(e) => setValueProtein(checkVal(e.target.value))} disabled={true} />g</div>
                                    {/* <div className="col"><input type="number" min="0"  value={valueProtein * 1.25} disabled />g</div> */}
                                </div>
                                <div className="row">
                                    <div className="col">Sól</div>
                                    <div className="col"><input type={autoOrManualTable == "manual" ? "text" : "number"} min="0" value={valueSalt} onChange={(e) => setValueSalt(checkVal(e.target.value))} disabled={true} />g</div>
                                    {/* <div className="col"><input type="number" min="0" value={valueSalt * 1.25} disabled />g</div> */}
                                </div>
                            </div>
                        </div>
                        {errorCalculator &&
                            <span class="error-text error">Należy wypełnić dane, by obliczyć wartość energetyczną.</span>
                        }
                    </div>
                    <div className="input-row border">
                        <label>Dodatkowe piktogramy</label>
                        <div className="icons-wrapper">
                            {icons && icons.normal && icons.normal.map((icon, i) => (
                                <div className={`icon-box ${iconsNormal.includes(icon) ? 'active' : ''}`} key={i} onClick={() => toggleIcons(icon, "normal") }>
                                    <div className="icon">
                                        <img src={icon} alt={"Ikona"} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="icons-wrapper">
                            {icons && icons.so && icons.so.map((icon, i) => (
                                <div className={`icon-box ${iconsSO.includes(icon) ? 'active' : ''}`} key={i} onClick={() => toggleIcons(icon, "so") }>
                                    <div className="icon">
                                        <img src={icon} alt={"Ikona"} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="boxes">
                    {status &&
                        <StatusPage status={status} updateStatus={(val) => setStatus(val)} disabled />
                    }
                </div>
            </div>
        </div>   
    );
}

export default PageVersion;

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

const NotificationAlert = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ show, setShow ] = useState(false);
    let text = useSelector(state => state.notificationAlertText);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!load) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load && text && text.length > 0) {
            notiAlert();
        }
    }, [text])

    const notiAlert = async() => {
        await setShow(true);
        await setTimeout(() => {
            setShow(false);   
            dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `` }) 
        }, 5000);
    }
    
    return (
        <div className={"notification" + ( show ? ' visible' : '')} role="alert">
            <h2>Komunikat</h2>
            <div className="editor" tabIndex="0" role="textbox" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
}

export default NotificationAlert;

import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"

const StatusPage = ( { data, user, updatedAt } ) => {
    return (
        <>
        <div className="versions-page settings-box">
            <div className="header">
                <span>Obecna wersja</span>
            </div>
            <div className="content">
                <p>{user.name} {user.surname} {updatedAt}</p>
            </div>
        </div>
        <div className="versions-page settings-box">
            <div className="header">
                <span>Historia zmian</span>
            </div>
            <div className="content">
                {data && data.map((version, i) => (
                    <Link to={`/dashboard/labels/versions/page/${version.id}`} tabIndex="0" role="button" aria-label="Wersja" className="submenu">
                        <p>{version.user.name} {version.user.surname} {version.created_at}</p>
                    </Link>
                ))}
            </div>
        </div>
        </>
    );
}

export default StatusPage;

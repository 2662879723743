import React, { useState } from 'react';
import Loader from './../../components/loader/Loader';

const PageTitle = ( { title, subTitle, langData, loader } ) => {
    return (
        <div className="page-title-container">
            {title &&
                <h1>{title}
                {langData &&
                    <img src={langData.flag_icon} alt={"wersja językowa " + langData.shortcode} />
                }
                {loader &&
                    <div className="loader-wrapper loader-wrapper-inline">
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                }
                </h1>
            }
            {subTitle &&
                <p>{subTitle}</p>
            }
            <hr />
        </div>
    );
}

export default PageTitle;

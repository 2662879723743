import React from 'react';
import PageTitle from './../../../components/page-title/PageTitle';

const Welcome = () => {
    return (
        <div className="container welcome-container">
            <PageTitle title="Strona startowa" />
            <div className="information-box">
                <div className="info">
                    <p>i</p>
                </div>
                <div className="text">
                    <p>Zalogowałeś/aś się do CMS E-ETYKIETY</p>
                </div>
            </div>
        </div>
    );
}

export default Welcome;

import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import { useSelector } from 'react-redux'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadLabelsXlsx = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ data, setData ] = useState([]);
    let selectedCompany = useSelector(state => state.selectedCompany);

    useEffect(() => {
        if(!load) {
            generateData();
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load) {
            setLoad(false);
        }
    }, [selectedCompany])

    const generateData = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getLabelsExcel?selectedCompany=${selectedCompany}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response).data;
            setData(res);
        })
    }

    const onKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.target.click()
        }
    }

  return (
    <>
        <ExcelFile element={<div className="download" aria-label="Pobierz Etykiety do pliku XLSX" onKeyDown={(e) => onKeyDown(e)} role="button" tabIndex="0">Pobierz Etykiety do pliku (XLSX) <i className="material-icons">download</i></div>}>
            <ExcelSheet data={data} name="Etykiety">
                <ExcelColumn label="Nazwa handlowa" value="title" />
                <ExcelColumn label="Nazwa środka spożywczego" value="type_wine" />
                <ExcelColumn label="Dodatkowe dane szczegółowe" value="product_info" />
                <ExcelColumn label="Składniki" value="ingredients" />
                <ExcelColumn label="Rzeczywista zawartość alkoholu" value="alcohol_percent" />
                <ExcelColumn label="Zdjęcie mobilne" value="image_mobile" />
                <ExcelColumn label="Zdjęcie desktop" value="image_pc" />
                <ExcelColumn label="Wartość energetyczna KCAL" value="value_kcal" />
                <ExcelColumn label="Wartość energetyczna KJ" value="value_kj" />
                <ExcelColumn label="Białka" value="value_protein" />
                <ExcelColumn label="Sól" value="value_salt" />
                <ExcelColumn label="Tłuszcz" value="value_fat" />
                <ExcelColumn label="Kwasy tłuszczowe" value="value_fat_saturated" />
                <ExcelColumn label="Węglowodany" value="value_carbohydrates" />
                <ExcelColumn label="Cukry" value="value_carbohydrates_sugars" />
                <ExcelColumn label="Piktogramy" value="iconsNormal" />
                <ExcelColumn label="Piktogramy SO" value="iconsSO" />
            </ExcelSheet>
        </ExcelFile>
      {/* <div className="action enter" onClick={ () => download() }>Pobierz XLSX <i className="material-icons">download</i></div> */}
    </>
  );
};

export default DownloadLabelsXlsx;